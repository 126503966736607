import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {faBan} from "@fortawesome/free-solid-svg-icons/faBan";
import React, {useState} from "react";
import Input from "../../atoms/Input/Input";
import Select from "../../atoms/Select/Select";
import JsonTextarea from "../../atoms/JsonTextarea/JsonTextarea";
import {dispatchError} from "../../../utils/api";


export function ChannelViewBase(props) {
    var initState = Object.assign({...props.channel});
    var channelOptions = [{_id: "-1", name: ""}, ...props.channels];
    if (initState.channel == null) {

        initState.channel = channelOptions[0];
    }

    const [state, setState] = useState(initState);
    const onChange = (e, key) => {
        const {value} = e.target;
        setState(state => ({
                ...state,
                [key]: value

            })
        );
    };

    const onChangeChannel = (e, key) => {
        var newState = Object.assign({...state});
        newState[key] = channelOptions.find(channel => channel._id === e.target.value);
        setState(newState);
    };
    const onChangeJson = (e, key) => {
        var newState = Object.assign({...state});
        if (e === "") {
            newState[key] = {};
        } else {
            try {
                newState[key] = JSON.parse(e);

            } catch (e) {
                if (e instanceof SyntaxError) {
                    dispatchError(  )({status: e.statusCode, body: {message: `In ${key}: ${e.toString()}`}});

                }
            }

        }
        setState(newState);
    };


    const cancelEdit = () => {

        setState(props.channel);
        props.onCancel(props.channel);
    }
    const channelSelector = (channels, field) => {

        return (

            <div className="field">
                <label className="label">Channel</label>
                <div className="control">
                    <div className="select">
                        <select value={state[field]._id} onChange={e => onChangeChannel(e, field)}>
                            {channelOptions.map(channel => <option key={channel._id}
                                                                   value={channel._id}>{channel.name}</option>)}
                        </select>
                    </div>
                </div>
            </div>
        )
    }

    const handleUpdateChannel = () => {
        var newState = Object.assign({...state});

        if (newState.channel._id === "-1") {
            newState.channel = null;
        }
        props.onChange(newState);

    }


    return (
        <li className="todo box">

            <div className="content">
                <div>

                    {channelSelector(props.channels, "channel")}


                </div>


            </div>

            <nav className="level is-mobile">
                <div className="level-left">

                </div>
                <div className="level-right">

                        <span className="icon space-right" onClick={handleUpdateChannel}
                              onKeyPress={handleUpdateChannel}>
                  <FontAwesomeIcon icon={faSave} size="lg"/>
                </span>

                    <span className="icon" onClick={cancelEdit} onKeyPress={cancelEdit}>
                  <FontAwesomeIcon icon={faBan} size="lg"/>
                </span>
                </div>
            </nav>

        </li>
    )
}

ChannelViewBase.defaultProps = {
    channel: {
        channel: null,
        url: "",
        affiliateUrl: "",
        active: "active",
        comment: "",
    }

}
