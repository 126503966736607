import {createReducer} from '@reduxjs/toolkit'
import {addPrice, addPricePage, removePrice, setPrice, setPrices} from "../actions/prices";


const initialState = {prices: [], stores: []}

export const prices = createReducer(initialState, (builder) => {
    builder
        // .addCase(addPrice, (state, action) => {
        //     state.push(action.payload.price);
        //     return state;
        // })
        // .addCase(removePrice, (state, action) => {
        //     return state.filter(el => el._id !== action.payload.price._id);
        // })
        .addCase(setPrices, (state, action) => {
            return action.payload;
        })
        .addCase(addPricePage, (state, action) => {
            return Object.assign({...action.payload, prices: [...state.prices, ...action.payload.prices]});
        })
    // .addCase(setPrice, (state, action) => {
    //
    //     return state.map(price => (action.payload.price._id === price._id) ? action.payload.price : price);
    // })

})
