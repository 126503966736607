import request from 'superagent';
import { handleError, handleSuccess } from '../utils/api';

export const postProxy = (info) =>
  request
    .post('/api/Proxies')
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const getProxies = () =>
  request.get('/api/Proxies').then(handleSuccess).catch(handleError);

export const putProxy = (info) =>
  request.put('/api/Proxies').send(info).then(handleSuccess).catch(handleError);

export const deleteProxy = (info) =>
  request
    .delete('/api/Proxies/' + info._id + '/')
    .then(handleSuccess)
    .catch(handleError);
