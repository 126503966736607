import request from 'superagent';
import {handleError, handleSuccess} from "../utils/api";

export const postService = info =>
    request.post('/api/Services')
        .send(info)
        .then(handleSuccess)
        .catch(handleError);

export const getServices = () =>
    request.get('/api/Services')
        .then(handleSuccess)
        .catch(handleError);

export const putService = info =>
    request.put('/api/Services')
        .send(info)
        .then(handleSuccess)
        .catch(handleError);

export const deleteService = info =>
    request.delete('/api/Services/'+info._id + "/")
        .then(handleSuccess)
        .catch(handleError);
