import request from 'superagent';
import {handleSuccess, handleError} from '_utils/api';

export const postProduct = info => {
    info.stores = info.stores.map(store => Object.assign({...store, store: store.store._id}));
    info.categories = info.categories.map(c => c._id);
    return request.post('/api/products')
        .send(info)
        .then(handleSuccess)
        .catch(handleError);
}


export const getProducts = (query) =>

    request.get('/api/products')
        .query(query)
        .then(handleSuccess)
        .catch(handleError);

export const putProduct = info =>
    request.put('/api/products')
        .send(info)
        .then(handleSuccess)
        .catch(handleError);

export const deleteProduct = info =>

    request.delete('/api/products/' + info._id + "/")
        .then(handleSuccess)
        .catch(handleError);


