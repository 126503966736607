import request from 'superagent';
import {handleError, handleSuccess} from "../utils/api";

export const postPrice = info =>
    request.post('/api/Prices')
        .send(info)
        .then(handleSuccess)
        .catch(handleError);

export const getPrices = (query ) =>
    request.get('/api/Prices')
        .query(query)
        .then(handleSuccess)
        .catch(handleError);

export const putPrice = info =>
    request.put('/api/Prices')
        .send(info)
        .then(handleSuccess)
        .catch(handleError);

export const deletePrice = info =>
    request.delete('/api/Prices/'+info._id + "/")
        .then(handleSuccess)
        .catch(handleError);
