import {push} from 'connected-react-router';
import {store as RNC} from 'react-notifications-component';

import {logout} from '_actions/user';

export const handleSuccess = resp => {
    return resp.body
};

export const handleError = error => {
    if (error.response) {
        throw {status: error.response.statusCode, body: {message: error.response.text}};

    } else {
        const response = {status: 500, body: {message: 'Internal Server error'}};
        throw response;
    }
};

export const dispatchError = dispatch => res => {
    if (res.status === 401) {
        dispatch(logout());
        dispatch(push('/login'));
    }

    RNC.addNotification({
        title: `Error: ${res.status}`,
        message: res.body.message,
        type: 'danger',
        container: 'top-right',
        animationIn: ['animated', 'fadeInRight'],
        animationOut: ['animated', 'fadeOutRight'],
        dismiss: {
            duration: 5000,
        },
    });

    throw res;
};
