import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import R from 'ramda';

import {attemptGetChannels} from "../../../store/thunks/channels";
import ChannelTable from "../../organisms/ChannelTable/ChannelTable";
import DefaultSection from "../../templates/DefaultSection/DefaultSection";

export default function ChannelPage() {
    const dispatch = useDispatch();
    const {user} = useSelector(R.pick(['user']));

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (R.isEmpty(user)) {
            dispatch(push('/login'));
        } else {
            dispatch(attemptGetChannels())
                .then(() => setLoading(false));
        }
    }, []);

    return (
        <div className="todo-page page">
            <DefaultSection title={"Channels"}><ChannelTable loading={loading}/></DefaultSection>
        </div>
    );
}
