import { createAction } from '@reduxjs/toolkit';

export const addProxy = createAction('proxies/add', function prepare(proxy) {
  return {
    payload: {
      proxy,
    },
  };
});

export const removeProxy = createAction(
  'proxies/delete',
  function prepare(proxy) {
    return {
      payload: {
        proxy,
      },
    };
  }
);

export const setProxies = createAction(
  'proxies/set',
  function prepare(proxies) {
    return {
      payload: {
        proxies,
      },
    };
  }
);
export const setProxy = createAction('proxy/set', function prepare(proxy) {
  return {
    payload: {
      proxy,
    },
  };
});
