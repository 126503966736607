import {useAsyncDebounce} from "react-table";
import Select from "../../atoms/Select/Select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect} from "react";

import {attemptGetProducts} from "../../../store/thunks/products";
import {useDispatch} from "react-redux";
import Button from "react-bulma-companion/lib/Button";
import {dispatchError} from "../../../utils/api";
import EnableFilter from "./EnableFilter";

export function ProductFilter({
                                  stores, categories, initFilter, handleRefresh, filterCallback
                              }) {
    const [state, setState] = React.useState(initFilter)


    useEffect(() => {
        if( stores != null){
            setState(state => ({...state, stores: stores.map(s => s._id), categories: categories.map(s => s._id)}))

        }

        filterCallback(getFilterForRequest({
            ...state,
            stores: stores.map(s => s._id),
            categories: categories.map(s => s._id)
        }));

    }, [stores,categories]);
    const dispatch = useDispatch();

    const [enable, setEnable] = React.useState(false);
    const [expand, setExpand] = React.useState(false);
    const getFilterForRequest = state => {
        var tmpState = Object.assign({...state});
        if (tmpState.disableStoreFilter) {
            tmpState.stores = "all";
        }
        if (tmpState.disableCategoriesFilter) {
            tmpState.categories = "all";
        }
        Object.keys(tmpState).forEach(k => tmpState[k] === "all" ? delete tmpState[k] : null);
        return tmpState;
    }
    const onChange = useAsyncDebounce(state => {


        if (state.stores.length === 0) {
            dispatchError()({status: 400, body: {message: `Please specify at least 1 store`}});
        } else {

            const tmpState = getFilterForRequest(state);
            filterCallback(tmpState);
           handleRefresh(tmpState);

        }
        setEnable(false);
    }, 200)
    const handleOnChangeKeyActive = (e, field) => {
        setState({...state, [field]: e.target.value});
        setEnable(true);
    }

    const handleOnChangeDisableAll = (key) => () => {
        setState(state => ({...state, [key]: []}));
        setEnable(true);
    }
    const handleOnChangeEnableAll = (key, v) => () => {
        setState(state => ({...state, [key]: v.map(s => s._id)}));
        setEnable(true);
    }

    const handleOnChangeEnable = (key) => (store) => () => {
        var nst = Object.assign(state);

        if (nst[key].includes(store._id)) {
            nst[key] = nst[key].filter(s => s !== store._id);
            setState(state => ({...state, [key]: state[key].filter(s => s !== store._id)}));
        } else {
            setState(state => ({...state, [key]: [...state[key], store._id]}));
        }
        setEnable(true);
    }

    const handleDisableStoreFilter = (t) => {
        setState(state => ({...state, disableStoreFilter: t}));
        setEnable(true);
    }
    const handleDisableCategoriesFilter = (t) => {
        setState(state => ({...state, disableCategoriesFilter: t}));
        setEnable(true);
    }
    return (
        <div className="filter-box box">
            <article className="media">
                <div className="media-content">
                    {expand ?
                        <div className="content">

                            <Select state={state} onChange={handleOnChangeKeyActive}
                                    field={"productActive"}
                                    options={["active", "off", "test", "all"]}/>

                            <Select state={state} onChange={handleOnChangeKeyActive}
                                    field={"storeActive"}
                                    options={["active", "off", "test", "all"]}/>
                            <Select state={state} onChange={handleOnChangeKeyActive}
                                    field={"categoryActive"}
                                    options={["active", "off", "all"]}/>

                            <EnableFilter name={"Stores"} handleDisableFilter={handleDisableStoreFilter}
                                          handleOnChangeEnableAll={handleOnChangeEnableAll("stores", stores)}
                                          handleOnChangeDisableAll={handleOnChangeDisableAll("stores")}
                                          handleOnChangeEnable={handleOnChangeEnable("stores")}
                                          state={state.stores} options={stores} disableFilter={state.disableStoreFilter}

                            />
                            <EnableFilter name={"Categories"} handleDisableFilter={handleDisableCategoriesFilter}
                                          handleOnChangeEnableAll={handleOnChangeEnableAll("categories", categories)}
                                          handleOnChangeDisableAll={handleOnChangeDisableAll("categories")}
                                          handleOnChangeEnable={handleOnChangeEnable("categories")}
                                          state={state.categories} options={categories}
                                          disableFilter={state.disableCategoriesFilter}

                            />

                            <Button color="success" disabled={!enable} onClick={e => onChange(state)}
                                    fullwidth>Apply</Button>
                        </div> :
                        <div onClick={() => setExpand(!expand)}><label className="label">{"Filter"}</label></div>

                    }

                </div>
                <a className={"media-right"} onClick={() => setExpand(!expand)}>
                    <FontAwesomeIcon icon={!expand ? faChevronDown : faChevronUp} size="lg"/>
                </a>
            </article>
        </div>

    )
}

ProductFilter.defaultProps = {
    initFilter: {
        productActive: "active",
        storeActive: "active",
        categoryActive: "all",
        disableStoreFilter: false,
        disableCategoriesFilter: true
    },
    filterCallback: () => null,
}
