import React, {useState} from "react";
import {sortByName} from "../../../utils/sorting";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Column from "react-bulma-companion/lib/Column";
import Columns from "react-bulma-companion/lib/Columns";


export default function EnableFilter({name, handleDisableFilter, handleOnChangeEnable, handleOnChangeDisableAll, handleOnChangeEnableAll, state, options, disableFilter}) {
    const [expand,setExpand] = useState(false);
    return (
        <Columns>
            <Column size="11" className="has-text-left">

                {expand ?
                    <>
                        <div className="field"><label className="label">  {name}</label>
                            <ul className="checkbox-grid" style={{"marginLeft": 0}}>
                                <li>
                                    <label className="checkbox">
                                        <input type="checkbox" className={"checkbox"}
                                               checked={disableFilter}
                                               onChange={() => {

                                                   handleDisableFilter(!disableFilter);
                                               }}>
                                        </input>
                                        {` Disable ${name} Filter`}
                                    </label>
                                </li>
                                <li>
                                    <label className="checkbox">
                                        <input type="checkbox" className={"checkbox"}
                                               disabled={disableFilter}
                                               checked={state.length === 0}
                                               onChange={handleOnChangeDisableAll}>
                                        </input>
                                        {` Disable all ${name}`}
                                    </label>
                                </li>
                                <li>
                                    <label className="checkbox">
                                        <input type="checkbox" className={"checkbox"}
                                               disabled={disableFilter}
                                               checked={state.length === options.length}
                                               onChange={handleOnChangeEnableAll}>
                                        </input>
                                        {` Enable all ${name}`}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="field">
                            <ul className="checkbox-grid" style={{"marginLeft": 0}}>

                                {options.slice().sort(sortByName).map(store =>

                                    <li key={store._id}>
                                        <label className="checkbox">
                                            <input type="checkbox" className={"checkbox"}
                                                   disabled={disableFilter}
                                                   checked={state.includes(store._id)}
                                                   onChange={handleOnChangeEnable(store)}>
                                            </input>
                                            {" " + store.name}
                                        </label>

                                    </li>)}

                            </ul>
                        </div>
                    </> :
                    <div onClick={() => setExpand(!expand)}><label className="label">{name}</label></div>

                }


            </Column>
            <Column size="1"  className="has-text-left">
                <a className={"media-right"} onClick={() => setExpand(!expand)}>
                    <FontAwesomeIcon icon={!expand ? faChevronDown : faChevronUp} size="lg"/>
                </a>
            </Column>



        </Columns>
    )
}
