import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import R from "ramda";
import Table from "../../molecules/Table/Table";

import {
    attemptDeletePrice,
    attemptGetPricePage,
    attemptGetPrices,
    attemptUpdatePrice
} from "../../../store/thunks/prices";
import Chart from "../Chart/Chart";
import {sortByCount} from "../../../utils/sorting";
import BarChart from "../BarChart/BarChart";
import Column from "react-bulma-companion/lib/Column";
import {getAvgOfPrices, getAvgOfPricesOfXDays, getLowestOfPricesOfXDays} from "../../../utils/transformation";


function PriceTable({loading}) {
    const dispatch = useDispatch();

    const deletePrice = (price) => dispatch(attemptDeletePrice(price));
    const updatePrice = (price) => dispatch(attemptUpdatePrice(price));


    const {prices, stores, total, pageSize} = useSelector(R.pick(['prices'])).prices;
    const columns = React.useCallback(() => [
        {
            Header: "Prices",
            columns: [
                {
                    Header: "Lego Number",
                    id: "altId",
                    accessor: (row) => {
                        if (row.product) {
                            return row.product.altId
                        }
                        return ""
                    },
                },
                {
                    Header: 'Name',
                    id: "name",
                    accessor: (row) => row.product ? row.product.name : "",
                },
                {
                    Header: 'Last Checked',
                    id: "lastChecked",
                    accessor: (row) => row.product && row.product.lastChecked ? new Date(row.product.lastChecked).toUTCString() : "",
                },
                {
                    Header: 'Posted',
                    id: "post.at",
                    accessor: (row) => row.product && row.product.post ? new Date(row.product.post.at).toUTCString() : "",
                },
                ...stores.slice().filter(s => s.name !== "").map((s) => ({
                    Header: s.name, accessor: (row) => {
                        const shopIndex = row.store.findIndex(st => st === s.name);
                        if (shopIndex >= 0) {
                            return row.prices[shopIndex][row.prices[shopIndex].length - 1]
                        }
                        return null;
                    },
                    disableSortBy: true
                }))
            ],
        },
    ], [stores]);
    const renderRowSubComponent = React.useCallback(
        (price, onCancel) => (
            <>
                <Column size="12" className="has-text-left">
                    <nav className="level">
                        <div className="level-item has-text-centered">
                            <div>
                                <p className="heading">Avg. Price</p>
                                <p className="title">{getAvgOfPrices(price.prices)}</p>
                            </div>
                        </div>
                        <div className="level-item has-text-centered">
                            <div>
                                <p className="heading">100 Days Avg. Price</p>
                                <p className="title">{getAvgOfPricesOfXDays(price.prices, price.timestamps, 100)}</p>
                            </div>
                        </div>
                        <div className="level-item has-text-centered">
                            <div>
                                <p className="heading">Lowest Price </p>
                                <p className="title">{price.lowest}</p>
                            </div>
                        </div>
                        <div className="level-item has-text-centered">
                            <div>
                                <p className="heading">100 Days Lowest Price</p>
                                <p className="title">{getLowestOfPricesOfXDays(price.prices, price.timestamps, 100)}</p>
                            </div>
                        </div>
                    </nav>
                </Column>
                <Chart data={toChartData(price)}/>
            </>
        ),
        []
    )
    const addRow = React.useCallback(() => (<></>), []);


    const toChartData = (prices) => {
        const {product} = prices;
        if (product == null || product.name == null) return null;

        // const avg = getAvgOfPrices(prices.prices);
        // const avg100 = getAvgOfPricesOfXDays(prices.prices, prices.timestamps, 100);
        // const lowest = prices.lowest;
        // const lowest100 = getLowestOfPricesOfXDays(prices.prices, prices.timestamps, 100);
        // console.log(prices.timestamps.map(t => new Date(t[0])));
        // const minDate = prices.timestamps.map(t => new Date(t[0])).reduce((a, b) => a < b ? a : b, new Date(Date.now()));
        // const maxDate = Date.now();
        // const toLine = (minDate, maxDate, val, label) => ({
        //     label: label,
        //     data: [[minDate, val], [maxDate, val]]
        // });
        // const statstics = [
        //     toLine(minDate, maxDate, avg, "avg"),
        //     toLine(minDate, maxDate, avg100, "avg100"),
        //     toLine(minDate, maxDate, lowest, "lowest"),
        //     toLine(minDate, maxDate, lowest100, "lowest100"),
        // ]

        const chartPrices = prices.store.map((store, i) =>
            ({
                label: store,
                data: prices.prices[i].map((p, j) => [new Date(prices.timestamps[i][j]), p])
            }))


        // let salesRanks = product.salesRanks != null ?
        //     Object.keys(product.salesRanks).map(k => ({
        //         label: "SalesRank " + k,
        //         data: toChartFormatfromKeepa(prices.salesRanks[k])
        //     })) : []
        return [
            ...chartPrices,
            // ...statstics
            // ...salesRanks


        ];


    }

    const data = [{
        data: stores.slice().filter(s => s.name !== "").slice(0, 30).map(s => ({
            primary: s.name,
            secondary: s.count
        }))
    }];


    return (
        <>

            <BarChart data={data}/>

            <Table columns={columns()} data={prices} totalPages={total}
                   renderRowSubComponent={renderRowSubComponent} addRow={addRow}
                   deleteRow={deletePrice} expander={true} edit={false} refresh={true}
                   handleRefresh={(pageSize, query) => dispatch(attemptGetPrices(0, pageSize, query))}
                   pageSize={pageSize}
                   handleLoadNewPage={(page, pageSize, query) => (dispatch(attemptGetPricePage(page, pageSize, query)))}
                   useLocalFilterAndSort={false}
                   hiddenColumns={stores.slice().sort(sortByCount).slice(3).map(s => s.name)}
                   useColumnSelector={true} loading={loading}/>
        </>


    )
}

export default PriceTable
