import React, { useState } from 'react';
import Buttons from 'react-bulma-companion/lib/Buttons';
import Button from 'react-bulma-companion/lib/Button';

import Input from '../../atoms/Input/Input';
import Textarea from '../../atoms/Textarea/Textarea';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import ProductStoreTable from '../../organisms/ProductStoreTable/ProductStoreTable';
import ProductChannelTable from '../../organisms/ProductChannelTable/ProductChannelTable';
import { replaceNullEntries } from '../../../utils/transformation';
import TagInput from '../../atoms/TagInput/TagInput';

export default function Product(props) {
  const { inDialog } = props;

  const { product, stores, categories, channels } = props;

  const [state, setState] = useState(replaceNullEntries(product));

  const onChange = (e, key) => {
    const { value } = e.target;
    setState((state) => ({
      ...state,
      [key]: value,
    }));
  };
  const onChangeStore = (index) => {
    return (store) => {
      setState((state) => {
        const newStores = Object.assign([...state.stores]);

        newStores[index] = store;
        return { ...state, stores: newStores };
      });
    };
  };
  const onChangeChannel = (index) => {
    return (store) => {
      setState((state) => {
        const newStores = Object.assign([...state.channels]);

        newStores[index] = store;
        return { ...state, channels: newStores };
      });
    };
  };
  const onDeleteChannel = (test, index) => {
    setState((state) => ({
      ...state,
      channels: state.channels.filter((store, i) => i !== index),
    }));
  };
  const onAddChannel = (store) => {
    setState((state) => ({ ...state, channels: [...state.channels, store] }));
  };
  const onDeleteStore = (test, index) => {
    setState((state) => ({
      ...state,
      stores: state.stores.filter((store, i) => i !== index),
    }));
  };

  const onAddStore = (store) => {
    setState((state) => ({ ...state, stores: [...state.stores, store] }));
  };
  const handleUpdateProduct = () => {
    props.onChange(state);
  };
  const cancelEdit = () => {
    props.onCancel();
    setState(product);
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: inDialog === true ? '0px' : '20px',
      }}
    >
      <div className="content">
        <div>
          <Input state={state} onChange={onChange} field={'name'} />
          <Input state={state} onChange={onChange} field={'series'} />

          <Input state={state} onChange={onChange} field={'model'} />

          <Input state={state} onChange={onChange} field={'brand'} />

          <Input state={state} onChange={onChange} field={'maxPrice'} />
          <Input state={state} onChange={onChange} field={'profitEstimates'} />
          <Input state={state} onChange={onChange} field={'image'} />
          <TagInput
            label={'Categories'}
            state={state.categories}
            suggestions={categories}
            onChange={(v) => setState((state) => ({ ...state, categories: v }))}
          />
          <Textarea
            state={state}
            onChange={onChange}
            field={'comment'}
            rows={3}
          />
          <Checkbox
            field={' deactivate all stores'}
            checked={!state.stores.find((store) => store.active !== 'off')}
            onChange={(e) => {
              var newState = Object.assign({ ...state });

              newState.stores = newState.stores.map((store) => {
                return { ...store, active: 'off' };
              });
              setState(newState);
            }}
          />

          <ProductStoreTable
            ogStores={stores}
            stores={state.stores}
            onAddStore={onAddStore}
            onDeleteStore={onDeleteStore}
            onChangeStore={onChangeStore}
          />

          <ProductChannelTable
            ogChannels={channels}
            channels={state.channels}
            onAddChannel={onAddChannel}
            onDeleteChannel={onDeleteChannel}
            onChangeChannel={onChangeChannel}
          />
        </div>
      </div>

      <nav className="level is-mobile">
        <div className="level-left"></div>
        <div className="level-right">
          <Buttons>
            <Button color="success" onClick={handleUpdateProduct}>
              Save Changes
            </Button>
            <Button color="danger" onClick={cancelEdit}>
              Cancel
            </Button>
          </Buttons>
        </div>
      </nav>
    </div>
  );
}
Product.defaultProps = {
  product: {
    name: '',
    series: '',
    model: '',
    brand: '',
    maxPrice: '',
    profitEstimates: '',
    stores: [],
    channels: [],
    categories: [],
    comment: '',
    image: '',
  },
};
