
import {addCategory, removeCategory, setCategory, setCategories} from "../actions/categories";
import {deleteCategory, getCategories, postCategory, putCategory} from "../../api/categories";
import {dispatchError} from "../../utils/api";

export const attemptGetCategories = () => dispatch =>
    getCategories()
        .then(data => {


            dispatch(setCategories(data.categories));
            return data.categories;
        })
        .catch(dispatchError(dispatch));

export const attemptUpdateCategory = (category) => dispatch =>
    putCategory(category)
        .then(data => {


            dispatch(setCategory(data.category));
            return data.category;
        })
        .catch(dispatchError(dispatch));
export const attemptAddCategory = (category) => dispatch =>
    postCategory(category)
        .then(data => {


            dispatch(addCategory(data.category));
            return category;
        })
        .catch(dispatchError(dispatch));
export const attemptDeleteCategory = (category) => dispatch =>
    deleteCategory(category)
        .then(() => {
            dispatch(removeCategory(category));
            return category;
        })
        .catch(dispatchError(dispatch));

