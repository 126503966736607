import Title from "react-bulma-companion/lib/Title";
import Chart from "./Chart";
import React from "react";
import Column from "react-bulma-companion/lib/Column";


const filterPriceSimple = (prices, epsilon = 10) => prices.map(price => price / 100).filter(e => e > epsilon)
const average = (array) => array.reduce((a, b) => a + b) / array.length;
const toDate = ts => new Date((ts + 21564000) * 60000);
const fromKeepaTimetoDate = ts => new Date((ts + 21564000) * 60000);
const toChartFormatfromKeepa = (data, k = 1) => {
    if (data == null) return [];
    let newData = []
    for (let i = 0; i < data.length; i += 2) {
        let date = fromKeepaTimetoDate(data[i]);
        let val = data[i + 1] / k;
        if (val > 0) {
            newData.push([date, data[i + 1] / k]);
        }
    }
    return newData
}

const toChartData = (product) => {
    console.log(product)
    if (product == null || product.name == null) return null;


    let amazonPriceHistory = []//toChartFormatfromKeepa(product.amazonPriceHistory,100);

    let salesRanks = product.salesRanks != null ?
        Object.keys(product.salesRanks).map(k => ({
            label: "SalesRank " + k,
            data: toChartFormatfromKeepa(product.salesRanks[k])
        })) : []
    return [


        {
            label: 'Amazon Price',
            data: amazonPriceHistory
        },
        ...salesRanks


    ];


}
const levelObject = levels => {
    return (
        <nav className="level">
            {
                levels.map(level =>
                    <div key={level[0]} className="level-item has-text-centered">
                        <div>
                            <p className="heading">{level[0]}</p>
                            <p className="title">{level[1]}</p>
                        </div>
                    </div>
                )
            }

        </nav>
    )
}

const field = (a, b) => {
    return (
        <tr>
            <td>{a}</td>
            <td>{b}</td>

        </tr>
    )
}

const filterTimestamps = (arr) => {
    return arr.filter((a, i) => i % 2 !== 0)
}
export default function Proposal(props) {

    const {proposal} = props;


    return (
        <div>

            <Title size="3" className="has-text-centered">
                {proposal.name}
            </Title>
            <Column size="12" className="has-text-left">
                {levelObject([["Amazon Avg", proposal.amazonAvgPrice], ["Amazon Current", proposal.amazonCurrentPrice], ["Amazon Realtime", "Not available"],])}
            </Column>
            <Column size="12" className="has-text-left">

                {levelObject([["Ebay Avg", proposal.ebayAvgPrice], ["Ebay Min", proposal.ebayMin], ["Ebay Max", proposal.ebayMax],])}

            </Column>
            <Column size="12" className="has-text-left">
                <table className={"table is-fullwidth"}>
                    <tbody>
                    {field("Category:", proposal.category)}

                    {field("Asin:", proposal.asin)}
                    {field("EbayUrl:", <a
                        href={proposal.ebaySearchUrl}>{proposal.ebaySearchUrl}</a>)}
                    {field("AmazonUrl:", <a href={proposal.amazonUrl}>{proposal.amazonUrl}</a>)}
                    {field("Amazon last Update:", new Date(proposal.lastUpdate).toString())}
                    {field("Ebay  Last Update:", new Date(proposal.ebayLastUpdate).toString())}
                    {field("Ebay Data", proposal.ebayCleanedData.length)}
                    {field("EAN:", proposal.eanList ? proposal.eanList.toString() : null)}
                    {field("Sales Rank:", (proposal.salesRanks != null) ?
                        <table className={"table is-fullwidth"}>
                            <tbody>
                            {Object.keys(proposal.salesRanks).map(k => field(k, filterTimestamps(proposal.salesRanks[k]).join()))}
                            </tbody>
                        </table> : null)}

                    {field("Lightning Deal:", (proposal.stats != null && proposal.stats.lightningDealInfo != null) ? toDate(proposal.stats.lightningDealInfo[0]) + "-" + toDate(proposal.stats.lightningDealInfo[1]) : null)}
                    </tbody>
                </table>

            </Column>
            <Chart data={toChartData(proposal)}/>

        </div>
    );

}

