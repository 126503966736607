import React from 'react'


import Table from "../../molecules/Table/Table";
import {StoreViewBase} from "../../molecules/Product/StoreViewBase";

import {faCheckCircle} from "@fortawesome/free-regular-svg-icons";
import {faEllipsisH, faExclamationCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {timeDuration} from "../../../utils/strings";
import { quantizeProductStoreStatus, StatusEnum} from "../../../utils/transformation";


function ProductStoreTableView(props) {

    const {ogStores, stores, onChangeStore, onDeleteStore, filter} = props;

    const columns = [
        {
            Header: "Store",
            columns: [
                {
                    Header: 'Product Status',
                    accessor: 'active',

                },
                {
                    Header: 'Store Status',
                    accessor: (row) => (row.store != null) ? row.store.active : null
                },
                {
                    Header: 'Store',
                    accessor: (row) => (row.store != null) ? row.store.name :
                        <p style={{color: "red"}}>Store unspecified/deleted</p>,
                },


            ],
        },
        {
            Header: "Log",
            columns: [
                {
                    Header: 'Status',
                    accessor: (row) => (row.log != null) ? row.log.result : null,
                    Cell: ({row}) => {
                        const status = quantizeProductStoreStatus(row.original);
                        var icon = faEllipsisH;
                        if (status === StatusEnum.warning) icon = faExclamationCircle;
                        else if (status === StatusEnum.error) icon = faTimes;
                        else if (status === StatusEnum.ok) icon = faCheckCircle;
                        return (<>

                        <span className="icon space-right">
                            {icon ? <FontAwesomeIcon icon={icon} size="lg"/> : null}
                        </span>

                        </>)
                    }
                },
                {
                    Header: 'Code',
                    accessor: (row) => (row.log != null) ? row.log.status : null,
                },
                {
                    Header: 'Timestamp',
                    accessor: (row) => (row.log != null && row.log.timestamp != null) ? new Date(row.log.timestamp).toLocaleString() : null,
                },
                {
                    Header: 'Interval',
                    accessor: (row) => (row.log != null && row.log.interval != null) ? timeDuration(row.log.interval) : null,
                },
                {
                    Header: 'Time Since Last Update',
                    accessor: (row) => (row.log != null && row.log.timeSinceLastUpdate != null) ? timeDuration(row.log.timeSinceLastUpdate) : null,
                },
                {
                    Header: 'Worker',
                    accessor: (row) => (row.log != null) ? row.log.worker : null,
                },
                {
                    Header: 'Message',
                    accessor: (row) => (row.log != null) ? row.log.msg : null,
                },
            ],
        },
    ]

    const renderRowSubComponent = (stores) => React.useCallback(
        (store, onCancel, index) => (
            <StoreViewBase
                store={store}
                stores={stores}
                onChange={onChangeStore(index)}

                onCancel={onCancel}
            />
        ),
        []
    )


    return (

        <Table header={true} search={false} pagination={false} edit={false} columns={columns} data={stores}
               renderRowSubComponent={renderRowSubComponent(ogStores)}
               deleteRow={onDeleteStore} filter={filter}/>

    )
}

export default ProductStoreTableView

ProductStoreTableView.defaultProps = {
    ogStores: [],
    onAddStore: () => void 0,
    onChangeStore: () => () => void 0,
    onDeleteStore: () => void 0,

}
