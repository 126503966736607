import React from 'react';

import Section from 'react-bulma-companion/lib/Section';
import Title from 'react-bulma-companion/lib/Title';
import Columns from 'react-bulma-companion/lib/Columns';
import Column from 'react-bulma-companion/lib/Column';


export default function StatusSection({title,children}) {
    return (
        <Section className="todo-section">
            <Title size="1" className="has-text-centered">
                {title}
            </Title>

            <Columns>
                <Column size="8" offset="2" className="has-text-left">
                    {children}
                </Column>
            </Columns>
        </Section>
    );
}
