
import {addChannel, removeChannel, setChannel, setChannels} from "../actions/channels";
import {deleteChannel, getChannels, postChannel, putChannel} from "../../api/channels";
import {dispatchError} from "../../utils/api";

export const attemptGetChannels = () => dispatch =>
    getChannels()
        .then(data => {


            dispatch(setChannels(data.channels));
            return data.channels;
        })
        .catch(dispatchError(dispatch));

export const attemptUpdateChannel = (channel) => dispatch =>
    putChannel(channel)
        .then(data => {


            dispatch(setChannel(data.channel));
            return data.channel;
        })
        .catch(dispatchError(dispatch));
export const attemptAddChannel = (channel) => dispatch =>
    postChannel(channel)
        .then(data => {


            dispatch(addChannel(data.channel));
            return channel;
        })
        .catch(dispatchError(dispatch));
export const attemptDeleteChannel = (channel) => dispatch =>
    deleteChannel(channel)
        .then(() => {
            dispatch(removeChannel(channel));
            return channel;
        })
        .catch(dispatchError(dispatch));
