import {createAction} from '@reduxjs/toolkit'

export const addPrice = createAction('prices/add', function prepare(price) {
    return {
        payload: {
            price,
        },
    }
})

export const removePrice = createAction('prices/delete', function prepare(price) {
    return {
        payload: {
            price,
        },
    }
})

export const addPricePage = createAction('prices/page/add', function prepare(page) {
    return {
        payload: {
          ...page
        },
    }
})
export const setPrices = createAction('prices/set', function prepare(page) {
    return {
        payload: {
            ...page
        },
    }
})
export const setPrice = createAction('price/set', function prepare(price) {
    return {
        payload: {
            price,
        },
    }
})
