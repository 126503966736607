import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { attemptAddCategory } from '../../../store/thunks/categories';
import { Dialog, Button } from 'evergreen-ui';
import { PlusIcon } from 'evergreen-ui';
import Category from '../Category/Category';

export default function AddCategory(props) {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  const editCategory = () => setEdit(true);

  const handleAddCategory = (category) => {
    dispatch(attemptAddCategory(category)).then(() => {
      setEdit(false);
    });
  };
  const onCancel = () => {
    setEdit(false);
  };

  return (
    <div>
      <Button iconBefore={PlusIcon} onClick={editCategory}>
        Add
      </Button>
      <Dialog
        isShown={edit}
        title="Neue Kategorie anlegen"
        onCloseComplete={onCancel}
        hasFooter={false}
      >
        <Category
          inDialog
          {...props}
          onChange={handleAddCategory}
          onCancel={onCancel}
        />
      </Dialog>
    </div>
  );
}
