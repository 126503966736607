import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { attemptAddStore } from '../../../store/thunks/stores';
import { Dialog, Button } from 'evergreen-ui';
import { PlusIcon } from 'evergreen-ui';
import Store from '../Store';

export default function AddStore(props) {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  const editStore = () => setEdit(true);

  const handleAddStore = (store) => {
    dispatch(attemptAddStore(store)).then(() => {
      setEdit(false);
    });
  };
  const onCancel = () => {
    setEdit(false);
  };

  return (
    <div>
      <Button iconBefore={PlusIcon} onClick={editStore}>
        Add
      </Button>
      <Dialog
        isShown={edit}
        title="Neuen Shop anlegen"
        onCloseComplete={onCancel}
        hasFooter={false}
      >
        <Store
          inDialog
          {...props}
          onChange={handleAddStore}
          onCancel={onCancel}
        />
      </Dialog>
    </div>
  );
}
