import {
  addProduct,
  addProductPage,
  removeProduct,
  setProduct,
  setProducts,
  setKleinanzeigeStats,
  setOtherStats,
} from '../actions/products';
import {
  deleteProduct,
  getProducts,
  postProduct,
  putProduct,
} from '../../api/products';
import { dispatchError } from '../../utils/api';

export const attemptGetProducts = (page, size, query) => (dispatch) =>
  getProducts({ ...query, page, size })
    .then((data) => {
      delete data.message;
      dispatch(setProducts(data));
      return data.products;
    })
    .catch(dispatchError(dispatch));

export const attemptGetKleinanzeigenStats = () => (dispatch) =>
  getProducts({
    disableStoreFilter: false,
    disableCategoriesFilter: true,
    stores: '61ea80eb0d2bb5001d36a0a6',
    page: undefined,
    size: undefined,
  })
    .then((data) => {
      delete data.message;
      return data.products;
    })
    .catch(dispatchError(dispatch));

export const attemptGetOtherStats = () => (dispatch) =>
  getProducts({
    storeActive: 'active',
    disableStoreFilter: true,
    disableCategoriesFilter: true,
    page: undefined,
    size: undefined,
  })
    .then((data) => {
      delete data.message;
      return data.products;
    })
    .catch(dispatchError(dispatch));

export const attemptGetProductPage = (page, size, query) => (dispatch) =>
  getProducts({ ...query, page, size })
    .then((data) => {
      delete data.message;
      dispatch(addProductPage(data));
      return data.products;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateProduct = (product) => (dispatch) =>
  putProduct(product)
    .then((data) => {
      dispatch(setProduct(data.product));
      return data.product;
    })
    .catch(dispatchError(dispatch));
export const attemptAddProduct = (product) => (dispatch) =>
  postProduct(product)
    .then((data) => {
      dispatch(addProduct(data.product));
      return data.product;
    })
    .catch(dispatchError(dispatch));
export const attemptDeleteProduct = (product) => (dispatch) =>
  deleteProduct(product)
    .then(() => {
      dispatch(removeProduct(product));
      return product;
    })
    .catch(dispatchError(dispatch));
