import {createReducer} from '@reduxjs/toolkit'
import {addProduct, addProductPage, removeProduct, setProduct, setProducts} from "../actions/products";
import {addPricePage} from "../actions/prices";


const initialState = {products: [], total: 1, pageSize: 50}

export const products = createReducer(initialState, (builder) => {
    builder
        .addCase(addProduct, (state, action) => {

            return {
                ...state,
                products: [...state.products, action.payload.Product],
                total: Math.ceil((state.products.length + 1) / state.pageSize)
            }
        })
        .addCase(removeProduct, (state, action) => {
            return {...state, products: state.products.filter(el => el._id !== action.payload.Product._id)};
        })
        .addCase(setProducts, (state, action) => {
            return action.payload;
        })
        .addCase(addProductPage, (state, action) => {
            return Object.assign({...action.payload, products: [...state.products, ...action.payload.products]});
        })
        .addCase(setProduct, (state, action) => {
            return {
                ...state,
                products: state.products.map(Product => (action.payload.Product._id === Product._id) ? action.payload.Product : Product)
            };
        })

})
