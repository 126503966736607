import React, {useEffect, useState} from 'react';
import request from "superagent";
import Chart from "../../organisms/Chart/Chart";
import InputWithButton from "../../atoms/InputWithButton/InputWithButton";


export default function AnalyticsPage() {
    const [data, setData] = useState({category: {}, indexes: [],counts: []});
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        (async () => {
            getData()
        })();
    }, []);
    const getData = () => {
        request.get("/api/proposals/analytics1")
            .query({category: query.split(",")})
            .then(resp => {

                setData({
                    category: {
                        name: resp.body.map(a => a.category.name).join(","),
                        listedItems: resp.body.map(a => a.category.listedItems).join(",")
                    },
                    indexes: resp.body.map((a) => (a.indexes.map((b,i)=>({label: a.category.name+i, data: b})))).flat(),


                    counts:  resp.body.map((a) => (a.counts.map((b,i)=>({label: a.category.name+i, data: b})))).flat(),
                });

                setLoading(false);

            });
    }

    return (!loading && (
        <div className="todo-page page">
            <InputWithButton state={{"search": query}} onChange={(e) => setQuery(e.target.value)} field={"CategoryId:"}
                             onClick={() => getData()}
                // onKeyPress={handleKeyPress}
                             buttonTitle={"Search"}/>
            <h3>{data.category.name}</h3>
            <h3>{data.category.listedItems}</h3>

            <Chart data={data.indexes}/>
            <Chart data={data.counts}/>
        </div>
    ));
}
