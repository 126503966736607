export const sortByProp = (a, b, prop) => {
  if (!a || !b) return 0;
  if (!a[prop] && !b[prop]) return 0;
  if (!b[prop]) return 1;
  if (!a[prop]) return -1;
  if (a[prop] > b[prop]) return 1;
  if (a[prop] < b[prop]) return -1;
  return 0;
};

export const sortByName = (a, b) => {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();
  if ( aName < bName ){
    return -1;
  }
  if ( aName > bName ){
    return 1;
  }
  return 0;
};

export const sortByOrder = (a, b) => {
  return sortByKey(a,b,"order")
};

export const sortByCount = (a, b, key) => {
  return sortByKey(b,a,"count");
};


export const sortByKey = (a, b, key) => {
  if (!a || !b) return 0;
  if (a[key] < b[key]) return -1;
  if (a[key] > b[key]) return 1;
  return 0;
};
