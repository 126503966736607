
import {addStore, removeStore, setStore, setStores} from "../actions/stores";
import {deleteStore, getStores, postStore, putStore} from "../../api/stores";
import {dispatchError} from "../../utils/api";

export const attemptGetStores = () => dispatch =>
    getStores()
        .then(data => {


            dispatch(setStores(data.stores));
            return data.stores;
        })
        .catch(dispatchError(dispatch));

export const attemptUpdateStore = (store) => dispatch =>
    putStore(store)
        .then(data => {


            dispatch(setStore(data.store));
            return data.store;
        })
        .catch(dispatchError(dispatch));
export const attemptAddStore = (store) => dispatch =>
    postStore(store)
        .then(data => {


            dispatch(addStore(data.store));
            return store;
        })
        .catch(dispatchError(dispatch));
export const attemptDeleteStore = (store) => dispatch =>
    deleteStore(store)
        .then(() => {
            dispatch(removeStore(store));
            return store;
        })
        .catch(dispatchError(dispatch));
