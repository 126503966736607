import React, {useState, useEffect} from 'react';
import Button from "react-bulma-companion/lib/Button";

import Proposal from "./Proposal";
import Input from "../../atoms/Input/Input";
import request from "superagent";
import {handleError, handleSuccess} from "../../../utils/api";


export default function ProposalPage() {
    const [dataSet, setData] = useState([]);
    const [index, setIndex] = useState(0);
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        (async () => {
            getData();
            getQueryString().then( () => setLoading(false));
        })();
    }, []);
    const getData = () => {
        request.get("/api/proposals")
            .then(resp => {                console.log(resp.body);
                setData(resp.body)});
    }
    const postQueryString = () => {
        request.post('/api/proposals/query')
            .send({query: JSON.parse( query)})
            .then(resp => {
                setQuery(JSON.stringify(resp.body.query));
                getData();
            })
            .catch(handleError);
    }
    const getQueryString = () => {
        return request.get('/api/proposals/query')
            .then(resp => setQuery(JSON.stringify(resp.body.query)))
            .catch(handleError);
    }
    const postToDiscord = () => {
        return request.post('/api/proposals/discord')
            .send({id: dataSet[index]._id})
            .then(resp => resp)
            .catch(handleError);
    }
    return (!loading && (
        <div  className="todo-page page">
            <Input state={{query:query}} onChange={(e, _) => setQuery(e.target.value)} field={"query"}/>
            <Button color="success" onClick={postQueryString}>Change Query String</Button>
            <Button color="success" onClick={postToDiscord}>Post to Discord</Button>
            {dataSet[index] != null ? <Proposal proposal={dataSet[index]}/> : null}
            <Button onClick={() => setIndex(Math.max(0, index - 1))}>Left</Button>
            <Button color="success" onClick={() => setIndex(Math.min(dataSet.length - 1, index + 1))}>Right</Button>

        </div>
    ));
}
