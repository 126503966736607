import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import R from 'ramda';
import GaugeChart from 'react-gauge-chart';
import Title from 'react-bulma-companion/lib/Box';
import Box from 'react-bulma-companion/lib/Box';
import Table from '../../molecules/Table/Table';
import ProductStoreTableView from '../ProductStoreTableView/ProductStoreTableView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faEllipsisH,
  faExclamationCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import {
  getAvgInterval,
  getAvgTimeSinceLastTimestep,
  getSumOfErrors,
  getSumOfWarnings,
  quantizeProductStatus,
  StatusEnum,
} from '../../../utils/transformation';

import Loader from 'react-bulma-companion/lib/Loader';
import Column from 'react-bulma-companion/lib/Column';
import { ProductFilter } from '../../molecules/ProductFilter/ProductFilter';
import {
  attemptGetKleinanzeigenStats,
  attemptGetOtherStats,
} from '../../../store/thunks/products';

const chartStyle = {
  height: 250,
  maxWidth: 500,
  margin: 'auto',
};

function StatusChart() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));
  const { products } = useSelector(R.pick(['products'])).products;
  const [loading, setLoading] = useState(true);
  const [chart1Data, setChart1Data] = useState(undefined);
  const [chart2Data, setChart2Data] = useState(undefined);
  const [chart1Percent, setChart1Percent] = useState(0);
  const [chart2Percent, setChart2Percent] = useState(0);
  const [isLoadingChart1, setIsLoadingChart1] = useState(true);
  const [isLoadingChart2, setIsLoadingChart2] = useState(true);

  const updateData = () => {
    setIsLoadingChart1(true);
    setIsLoadingChart2(true);
    dispatch(attemptGetKleinanzeigenStats()).then((data) => {
      setChart1Data(data);
      setChart1Percent(((1 / data.length) * getSumOfErrors(data)).toFixed(2));
      setIsLoadingChart1(false);
    });
    dispatch(attemptGetOtherStats()).then((data) => {
      setChart2Data(data);
      setChart2Percent(((1 / data.length) * getSumOfErrors(data)).toFixed(2));
      setIsLoadingChart2(false);
    });
  };

  useEffect(() => {
    updateData();

    const interval = setInterval(() => {
      updateData();
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box style={{ display: 'flex', backgroundColor: '#282c33' }}>
      <Column size="6">
        <GaugeChart
          animate={false}
          nrOfLevels={20}
          id={'chart-1'}
          percent={chart1Percent}
          style={chartStyle}
        />
        <div className="has-text-centered">
          <div>
            <p className="heading" style={{ color: 'white' }}>
              {isLoadingChart1 ? (
                <Loader style={{ margin: 'auto' }} />
              ) : (
                <>Kleinanzeigen Errors</>
              )}
            </p>
          </div>
        </div>
      </Column>
      <Column size="6">
        <GaugeChart
          animate={false}
          nrOfLevels={20}
          id={'chart-2'}
          percent={chart2Percent}
          style={chartStyle}
        />
        <div className="has-text-centered">
          <div>
            <p className="heading" style={{ color: 'white' }}>
              {isLoadingChart2 ? (
                <Loader style={{ margin: 'auto' }} />
              ) : (
                <>Rest Errors</>
              )}
            </p>
          </div>
        </div>
      </Column>
    </Box>
  );
}

export default StatusChart;
