import {createAction} from '@reduxjs/toolkit'

export const addChannel = createAction('channels/add', function prepare(channel) {
    return {
        payload: {
            channel,
        },
    }
})

export const removeChannel = createAction('channels/delete', function prepare(channel) {
    return {
        payload: {
            channel,
        },
    }
})

export const setChannels = createAction('channels/set', function prepare(channels) {
    return {
        payload: {
            channels,
        },
    }
})
export const setChannel = createAction('channel/set', function prepare(channel) {
    return {
        payload: {
            channel,
        },
    }
})
