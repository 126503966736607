import React from 'react'


import Table from "../../molecules/Table/Table";
import {ChannelViewBase} from "../../molecules/Product/ChannelViewBase";
import {AddChannelView} from "../../molecules/Product/AddChannelView";
import Select from "../../atoms/Select/Select";


function ProductChannelTable(props) {

    const {ogChannels, channels, onAddChannel, onChangeChannel, onDeleteChannel} = props;

    const columns = [
        {
            Header: "Channels",
            columns: [
                {

                    Header: 'Channel',
                    accessor: (row) => (row.channel != null) ? row.channel.name : <p style={{color:"red"}}>Channel unspecified/deleted</p>,

                }
            ],
        },
    ]

    const renderRowSubComponent = (channels) => React.useCallback(
        (channel, onCancel, index) => (
            <ChannelViewBase
                channel={channel}
                channels={channels}
                onChange={onChangeChannel(index)}

                onCancel={onCancel}
            />
        ),
        []
    )
    const addRow = React.useCallback(() => (<AddChannelView channels={ogChannels} onChange={onAddChannel}/>), [ogChannels]);

    return (

        <Table search={false} pagination={false} columns={columns} data={channels}
               renderRowSubComponent={renderRowSubComponent(ogChannels)} addRow={addRow}
               deleteRow={onDeleteChannel}/>

    )
}

export default ProductChannelTable
