import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Buttons from 'react-bulma-companion/lib/Buttons';
import Button from 'react-bulma-companion/lib/Button';

import Input from '../../atoms/Input/Input';
import JsonTextarea from '../../atoms/JsonTextarea/JsonTextarea';

export default function Channel(props) {
  const { inDialog } = props;

  const [state, setState] = useState(props.channel);
  const onChange = (e, key) => {
    const { value } = e.target;
    setState((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const onChangeJson = (e, key) => {
    var newState = Object.assign({ ...state });
    if (e === '') {
      newState[key] = {};
    } else {
      newState[key] = JSON.parse(e);
    }
    setState(newState);
  };
  const handleUpdateChannel = () => {
    props.onChange(state);
  };
  const cancelEdit = () => {
    props.onCancel();
    setState(props.channel);
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: inDialog === true ? '0px' : '20px',
      }}
    >
      <div className="content">
        <div>
          <Input state={state} onChange={onChange} field={'name'} />
          <Input state={state} onChange={onChange} field={'testChannel'} />
          <Input state={state} onChange={onChange} field={'webhookUrl'} />

          <JsonTextarea
            name={'countries'}
            size={5}
            value={state.countries}
            onBlur={onChangeJson}
          />
          <JsonTextarea
            name={'categories'}
            size={25}
            value={state.categories}
            onBlur={onChangeJson}
          />
        </div>
      </div>

      <nav className="level is-mobile">
        <div className="level-left"></div>
        <div className="level-right">
          <Buttons>
            <Button color="success" onClick={handleUpdateChannel}>
              Save Changes
            </Button>
            <Button color="danger" onClick={cancelEdit}>
              Cancel
            </Button>
          </Buttons>
        </div>
      </nav>
    </div>
  );
}

Channel.defaultProps = {
  channel: {
    name: '',
    testChannel: '',
    webhookUrl: '',
    countries: [],
    categories: [],
  },
};
