import {
  addProxy,
  removeProxy,
  setProxy,
  setProxies,
} from '../actions/proxies';
import {
  deleteProxy,
  getProxies,
  postProxy,
  putProxy,
} from '../../api/proxies';
import { dispatchError } from '../../utils/api';

export const attemptGetProxies = () => (dispatch) =>
  getProxies()
    .then((data) => {
      dispatch(setProxies(data.proxies));
      return data.proxies;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateProxy = (proxy) => (dispatch) =>
  putProxy(proxy)
    .then((data) => {
      dispatch(setProxy(data.proxy));
      return data.proxy;
    })
    .catch(dispatchError(dispatch));

export const attemptAddProxy = (proxy) => (dispatch) =>
  postProxy(proxy)
    .then((data) => {
      dispatch(addProxy(data.proxy));
      return proxy;
    })
    .catch(dispatchError(dispatch));

export const attemptDeleteProxy = (proxy) => (dispatch) =>
  deleteProxy(proxy)
    .then(() => {
      dispatch(removeProxy(proxy));
      return proxy;
    })
    .catch(dispatchError(dispatch));
