import {timeDuration} from "./strings";

export const replaceNullEntries = o => {
    var product = Object.assign({...o});
    Object.keys(product).filter(k => product[k] == null).forEach(k => product[k] = "");
    return product;

}
export const StatusEnum = Object.freeze({"error": 3, "warning": 2, "ok": 1, "default": 0});
export const quantizeProductStatus = (product) => {
    var status = StatusEnum.default;
    product.stores.some(store => {

        const s = quantizeProductStoreStatus(store);
        status = Math.max(s, status);
        return s === StatusEnum.error;
    })
    return status
};

export const quantizeProductStoreStatus = (store) => {

    if (store.log == null || store.log.result == null) return StatusEnum.default;
    if (store.log.result.toLowerCase().includes("error")) {
        return StatusEnum.error
    } else if (store.log.interval > 1000 * 60 * 20) {
        return StatusEnum.warning;
    } else if (!isBlank(store.log.result)) {
        return StatusEnum.ok;
    }
    return StatusEnum.default
}

export const getAvgOfProduct = (products, innerFunc) => products.reduce(
    (p, n) => {
        let sum = p[0];
        let cnt = p[1];
        let inner = innerFunc(n);


        return [sum + inner[0], cnt + inner[1]]

    }, [0, 0]);
export const getAvgInterval = (products) => {
    let sum = getAvgOfProduct(products, (n) => (n.stores.reduce((p1, n1) => {
            if (n1.log != null && n1.log.interval > 0) {
                return [p1[0] + n1.log.interval, p1[1] + 1];
            }
            return p1;
        }

        , [0, 0]))
    )
    return timeDuration(sum[1] > 0 ? sum[0] / sum[1] : NaN);
}
export const getAvgTimeSinceLastTimestep = (products) => {
    let sum = getAvgOfProduct(products, (n) => (n.stores.reduce((p1, n1) => {
            if (n1.log != null && n1.log.timeSinceLastUpdate > 0) {
                return [p1[0] + n1.log.timeSinceLastUpdate, p1[1] + 1];
            }
            return p1;
        }

        , [0, 0]))
    )
    return timeDuration(sum[1] > 0 ? sum[0] / sum[1] : NaN);
}
export const getSumOfErrors = (products) => {
    let sum = getAvgOfProduct(products, (n) => (n.stores.reduce((p1, store) => {
            if (quantizeProductStoreStatus(store) === StatusEnum.error) {
                return [p1[0] + 1, 0];
            }
            return p1;
        }

        , [0, 0]))
    )
    return sum[0];
}
export const getSumOfWarnings = (products) => {
    let sum = getAvgOfProduct(products, (n) => (n.stores.reduce((p1, store) => {
            if (quantizeProductStoreStatus(store) === StatusEnum.warning) {
                return [p1[0] + 1, 0];
            }
            return p1;
        }

        , [0, 0]))
    )
    return sum[0];
}

export const getAvgOfPrices = (prices) => {
    return avg(prices.flat()).toFixed(2);
}

const getDateXDaysAgo = (days) => {
    var dateOffset = (24 * 60 * 60 * 1000) * days; //5 days
    var myDate = new Date();
    myDate.setTime(myDate.getTime() - dateOffset);
    return myDate;
}
export const getAvgOfPricesOfXDays = (prices,timestamps,days) => {

    let startDate = getDateXDaysAgo(days);

    return  avg(prices
        .map((p, i) => {
            return p.filter((_, j) => new Date(timestamps[i][j]) > startDate);
        })
        .flat()).toFixed(2);
}
export const getLowestOfPricesOfXDays = (prices,timestamps,days) => {

    let startDate = getDateXDaysAgo(days);

    return  Math.min(...prices
        .map((p, i) => {
            return p.filter((_, j) => new Date(timestamps[i][j]) > startDate);
        })
        .flat()).toFixed(2);
}
function sum(arr) {
    return arr.reduce((a, b) => a + b, 0);
}

function avg(arr) {
    return sum(arr) / arr.length;
}


function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}
