import React, {useState} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBan} from '@fortawesome/free-solid-svg-icons/faBan';
import {faSave} from '@fortawesome/free-solid-svg-icons/faSave';


import Input from "../../atoms/Input/Input";
import InputWithButton from "../../atoms/InputWithButton/InputWithButton";
import {generatePassword} from "../../../utils/strings";

export default function Service(props) {

    const [state, setState] = useState(props.service);
    const onChange = (e, key) => {
        const {value} = e.target;
        console.log(value);
        setState(state => ({
                ...state,
                [key]: value

            })
        );
    };


    const handleUpdateService = () => {
        props.onChange(state);
    };
    const cancelEdit = () => {
        props.onCancel();
        setState(props.channel);
    }


    return (
        <li className="todo box">
            <article className="media">

                <div className="media-content">
                    <div className="content">

                        <Input state={state} onChange={onChange} field={"name"}/>

                        <InputWithButton state={state} onChange={onChange} field={"secret"}
                                         onClick={() => onChange({target: {value: generatePassword()}}, "secret")}
                                         buttonTitle={"Generate"}/>

                    </div>

                    <nav className="level is-mobile">
                        <div className="level-left">

                        </div>
                        <div className="level-right">

                                <span className="icon space-right" onClick={handleUpdateService}
                                      onKeyPress={handleUpdateService}>
                                    <FontAwesomeIcon icon={faSave} size="lg"/>
                                </span>

                            <span className="icon" onClick={cancelEdit} onKeyPress={cancelEdit}>
                                     <FontAwesomeIcon icon={faBan} size="lg"/>
                            </span>

                        </div>
                    </nav>
                </div>
            </article>

        </li>
    );
}

Service.defaultProps = {
    service: {
        name: "",
        secret: ""
    }
}
