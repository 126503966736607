import React from 'react';
import { useSelector } from 'react-redux';
import R from 'ramda';

import { useDispatch } from 'react-redux';
import Table from '../../molecules/Table/Table';
import {
  attemptDeleteStore,
  attemptGetStores,
  attemptUpdateStore,
} from '../../../store/thunks/stores';
import Store from '../../molecules/Store';
import AddStore from '../../molecules/AddStore';
import Select from '../../atoms/Select/Select';
import { attemptGetProducts } from '../../../store/thunks/products';

function StoreTable({ loading }) {
  const dispatch = useDispatch();

  const deleteStore = (store) => dispatch(attemptDeleteStore(store));
  const updateStore = (store) => dispatch(attemptUpdateStore(store));

  const onChange = (e, store, field) => {
    var newState = Object.assign({ ...store });
    newState[field] = e.target.value;
    dispatch(attemptUpdateStore(newState));
  };

  const columns = [
    {
      Header: 'Shops',
      columns: [
        {
          Header: 'Active',
          accessor: 'active',
          Cell: ({ cell }) => {
            return (
              <>
                <Select
                  label={false}
                  state={cell.row.original}
                  onChange={(e, field) => {
                    onChange(e, cell.row.original, field);
                  }}
                  field={'active'}
                  options={['active', 'test', 'off']}
                />
              </>
            );
          },
        },
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Timeout',
          accessor: 'timeout',
        },

        {
          Header: 'Currency',
          accessor: 'currency',
        },
        {
          Header: 'Backoff Status codes',
          accessor: (row) => JSON.stringify(row.backoffStatusCodes),
        },
      ],
    },
  ];

  const { stores } = useSelector(R.pick(['stores']));
  const { proxies } = useSelector(R.pick(['proxies']));

  const renderRowSubComponent = React.useCallback(
    (store, onCancel) => (
      <Store
        key={store._id}
        store={store}
        proxies={proxies}
        onChange={updateStore}
        onCancel={onCancel}
        inDialog={false}
      />
    ),
    [proxies]
  );
  const addRow = React.useCallback(() => <AddStore proxies={proxies} />, []);

  return (
    <Table
      columns={columns}
      data={stores}
      loading={loading}
      renderRowSubComponent={renderRowSubComponent}
      addRow={addRow}
      deleteRow={deleteStore}
      refresh={true}
      handleRefresh={(_) => dispatch(attemptGetStores())}
    />
  );
}

export default StoreTable;
