import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { attemptAddProduct } from '../../../store/thunks/products';
import { Dialog, Button } from 'evergreen-ui';
import { PlusIcon } from 'evergreen-ui';
import Product from '../Product';

export default function AddProduct(props) {
  const dispatch = useDispatch();

  const handleAddProduct = (product) => {
    dispatch(attemptAddProduct(product));
    setEdit(false);
  };

  const [edit, setEdit] = useState(false);

  const editProduct = () => setEdit(true);

  const onCancel = () => {
    setEdit(false);
  };

  return (
    <div>
      <Button iconBefore={PlusIcon} onClick={editProduct}>
        Add
      </Button>
      <Dialog
        isShown={edit}
        title="Neues Produkt anlegen"
        onCloseComplete={onCancel}
        hasFooter={false}
      >
        <Product
          inDialog
          {...props}
          onChange={handleAddProduct}
          onCancel={onCancel}
        />
      </Dialog>
    </div>
  );
}
