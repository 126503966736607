import React from 'react'
import {Chart as ReactChart, curveStepAfter} from 'react-charts'


export default function Chart({data}) {

    const [{min, max}, setState] = React.useState({
        min: null,
        max: null
    })

    document.addEventListener("contextmenu", (event) => {
        event.preventDefault()
    });
    const axes = React.useMemo(
        () => [
            {
                primary: true,
                type: 'time',
                position: 'bottom',
                hardMin: min,
                hardMax: max,
            },
            {
                type: 'linear',
                position: 'left',
            }
        ],
        [max, min]
    )
   //todo custom tooltip
    const onRightClick = (e) => {
        if (e.button === 2) {

            setState({
                min: null,
                max: null
            })
        }
    }
    const brush = React.useMemo(
        () => ({
            onSelect: (brushData) => {
                setState({
                    min: Math.min(brushData.start, brushData.end),
                    max: Math.max(brushData.start, brushData.end)
                })

            }

        }),
        []
    )
    const series = React.useMemo(() => ({curve: curveStepAfter}), [])
    return (

        <div>
            <ReactChart primaryCursor style={{
                width: '90%',
                height: '400px'
            }}
                        series={series}
                        data={data ? data : []}
                        axes={axes} tooltip brush={brush}
                        onContextMenu={onRightClick}
            />

        </div>
    )

}

