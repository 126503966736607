import {
  addService,
  removeService,
  setService,
  setServices,
} from '../actions/services';
import {
  deleteService,
  getServices,
  postService,
  putService,
} from '../../api/services';
import { dispatchError } from '../../utils/api';

export const attemptGetServices = () => (dispatch) =>
  getServices()
    .then((data) => {
      dispatch(setServices(data.services));
      return data.services;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateService = (service) => (dispatch) =>
  putService(service)
    .then((data) => {
      dispatch(setService(data.service));
      return data.service;
    })
    .catch(dispatchError(dispatch));
export const attemptAddService = (service) => (dispatch) =>
  postService(service)
    .then((data) => {
      dispatch(addService(data.service));
      return service;
    })
    .catch(dispatchError(dispatch));
export const attemptDeleteService = (service) => (dispatch) =>
  deleteService(service)
    .then(() => {
      dispatch(removeService(service));
      return service;
    })
    .catch(dispatchError(dispatch));
