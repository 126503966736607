import {createReducer} from '@reduxjs/toolkit'
import {addChannel, removeChannel, setChannel, setChannels} from "../actions/channels";


const initialState = []

export const channels = createReducer(initialState, (builder) => {
    builder
        .addCase(addChannel, (state, action) => {
            state.push(action.payload.channel);
            return state;
        })
        .addCase(removeChannel, (state, action) => {
            return state.filter(el => el._id !== action.payload.channel._id);
        })
        .addCase(setChannels, (state, action) => {
            return action.payload.channels;
        })
        .addCase(setChannel, (state, action) => {

            return state.map(channel => (action.payload.channel._id === channel._id) ? action.payload.channel : channel);
        })

})
