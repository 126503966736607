import React from "react";

export default function Checkbox(props) {
    const {onChange, state, field,checked} = props;
    return (
        <div className="field">
            <label className="label">{field}</label>
            <div className="control">

                <input type="checkbox"
                       onChange={onChange}  checked={checked}/>
            </div>
        </div>
    )
}
