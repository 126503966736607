import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import R from 'ramda';
import GaugeChart from 'react-gauge-chart';
import Title from 'react-bulma-companion/lib/Box';
import Box from 'react-bulma-companion/lib/Box';
import Table from '../../molecules/Table/Table';
import ProductStoreTableView from '../ProductStoreTableView/ProductStoreTableView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faEllipsisH,
  faExclamationCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import {
  getAvgInterval,
  getAvgTimeSinceLastTimestep,
  getSumOfErrors,
  getSumOfWarnings,
  quantizeProductStatus,
  StatusEnum,
} from '../../../utils/transformation';

import Column from 'react-bulma-companion/lib/Column';
import Columns from 'react-bulma-companion/lib/Column';
import { ProductFilter } from '../../molecules/ProductFilter/ProductFilter';
import {
  attemptGetProductPage,
  attemptGetProducts,
} from '../../../store/thunks/products';

function StatusTable({ loading }) {
  const columns = [
    {
      Header: 'Products',
      columns: [
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Categories',
          accessor: (row) => {
            return row.categories.map((c) => c.name);
          },
          Cell: ({ row }) => {
            return (
              <div>
                {row.original.categories.map((c) => (
                  <span key={c._id} className={'tag is-link'}>
                    {c.name}
                  </span>
                ))}
              </div>
            );
          },
        },
        {
          Header: 'Model',
          accessor: 'model',
        },
        {
          Header: 'Max Price',
          accessor: 'maxPrice',
        },

        {
          Header: 'Status',
          accessor: (row) => {
            return quantizeProductStatus(row);
          },
          Cell: ({ row }) => {
            const status = quantizeProductStatus(row.original);
            var icon = faEllipsisH;
            if (status === StatusEnum.warning) icon = faExclamationCircle;
            else if (status === StatusEnum.error) icon = faTimes;
            else if (status === StatusEnum.ok) icon = faCheckCircle;
            return (
              <>
                <span
                  className="icon space-right"
                  onClick={(e) => row.toggleRowExpanded()}
                  onKeyPress={(e) => row.toggleRowExpanded()}
                >
                  <FontAwesomeIcon icon={icon} size="lg" />
                </span>
              </>
            );
          },
        },
      ],
    },
  ];

  const { products } = useSelector(R.pick(['products'])).products;
  const { stores } = useSelector(R.pick(['stores']));
  const { channels } = useSelector(R.pick(['channels']));
  const { categories } = useSelector(R.pick(['categories']));
  const dispatch = useDispatch();

  const renderRowSubComponent = React.useCallback(
    (product, onCancel, _, filter) => (
      <ProductStoreTableView header={false} stores={product.stores} />
    ),
    [stores, channels]
  );

  console.log('products: ', products);
  const renderFilterBox = React.useCallback(
    (preGlobalFilteredRows, filterCallback) => (
      <ProductFilter
        stores={stores}
        categories={categories}
        preGlobalFilteredRows={preGlobalFilteredRows}
        handleRefresh={(filter) =>
          dispatch(attemptGetProducts(null, null, filter))
        }
        filterCallback={filterCallback}
      />
    ),
    [stores, categories]
  );

  return (
    <div>
      <Column
        size="12"
        className="has-text-left"
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '6px',
          boxShadow: '0px 0px 10px -1px #d7d7d7',
        }}
      >
        <nav className="level">
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Avg. Interval</p>
              <p className="title">{getAvgInterval(products)}</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Avg. Time since last Update</p>
              <p className="title">{getAvgTimeSinceLastTimestep(products)}</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Warnings</p>
              <p className="title">{getSumOfWarnings(products)}</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Errors</p>
              <p className="title">{getSumOfErrors(products)}</p>
            </div>
          </div>
        </nav>
      </Column>
      <br />
      <Table
        columns={columns}
        data={products}
        renderRowSubComponent={renderRowSubComponent}
        filterBox={renderFilterBox}
        loading={loading}
        deleteRow={() => null}
        edit={false}
        expander={true}
        displayFilter={true}
        refresh={true}
        handleLoadNewPage={(page, pageSize, query) =>
          dispatch(attemptGetProductPage(null, null, query))
        }
        handleRefresh={(pageSize, query) =>
          dispatch(attemptGetProducts(null, null, query))
        }
      />
    </div>
  );
}

export default StatusTable;
