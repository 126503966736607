import React from "react";

export default function Textarea(props) {
    const {onChange, state, field, rows} = props;
    return (
        <div className="field">
            <label className="label">{field}</label>
            <div className="control">
                <textarea className="textarea" rows={rows}
                          onChange={e => onChange(e, field)} value={state[field]}/>

            </div>
        </div>
    )
}
