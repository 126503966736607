import {addPrice, addPricePage, removePrice, setPrice, setPrices} from "../actions/prices";
import {deletePrice, getPrices, postPrice, putPrice} from "../../api/prices";
import {dispatchError} from "../../utils/api";

export const attemptGetPrices = (page, size, query) => dispatch =>
    getPrices({...query, page, size})
        .then(data => {
            delete data.message

            dispatch(setPrices(data));
            return data.prices;
        })
        .catch(dispatchError(dispatch));
export const attemptGetPricePage = (page, size, query) => dispatch =>
    getPrices({...query, page, size})
        .then(data => {
            delete data.message

            dispatch(addPricePage(data));
            return data.prices;
        })
        .catch(dispatchError(dispatch));
export const attemptUpdatePrice = (price) => dispatch =>
    putPrice(price)
        .then(data => {


            dispatch(setPrice(data.price));
            return data.price;
        })
        .catch(dispatchError(dispatch));
export const attemptAddPrice = (price) => dispatch =>
    postPrice(price)
        .then(data => {


            dispatch(addPrice(data.price));
            return price;
        })
        .catch(dispatchError(dispatch));
export const attemptDeletePrice = (price) => dispatch =>
    deletePrice(price)
        .then(() => {
            dispatch(removePrice(price));
            return price;
        })
        .catch(dispatchError(dispatch));
