import { createAction } from '@reduxjs/toolkit';

export const addProduct = createAction(
  'Products/add',
  function prepare(Product) {
    return {
      payload: {
        Product,
      },
    };
  }
);

export const removeProduct = createAction(
  'Products/delete',
  function prepare(Product) {
    return {
      payload: {
        Product,
      },
    };
  }
);

export const setProducts = createAction('Products/set', function prepare(page) {
  return {
    payload: {
      ...page,
    },
  };
});

export const addProductPage = createAction(
  'Products/page/add',
  function prepare(page) {
    return {
      payload: {
        ...page,
      },
    };
  }
);
export const setProduct = createAction(
  'Product/set',
  function prepare(Product) {
    return {
      payload: {
        Product,
      },
    };
  }
);

// export const setKleinanzeigeStats = createAction(
//   'Stats/Kleinanzeigen/set',
//   function prepare(page) {
//     return {
//       payload: {
//         ...page,
//       },
//     };
//   }
// );

// export const setOtherStats = createAction(
//   'Stats/Other/set',
//   function prepare(page) {
//     return {
//       payload: {
//         ...page,
//       },
//     };
//   }
// );
