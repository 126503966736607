import request from 'superagent';
import {handleError, handleSuccess} from "../utils/api";

export const postChannel = info =>
    request.post('/api/Channels')
        .send(info)
        .then(handleSuccess)
        .catch(handleError);

export const getChannels = () =>
    request.get('/api/Channels')
        .then(handleSuccess)
        .catch(handleError);

export const putChannel = info =>
    request.put('/api/Channels')
        .send(info)
        .then(handleSuccess)
        .catch(handleError);

export const deleteChannel = info =>
    request.delete('/api/Channels/'+info._id + "/")
        .then(handleSuccess)
        .catch(handleError);
