import React from "react";

export default function Input  (props) {
    const {onChange, state,field } = props;
    return (
        <div className="field">
            <label className="label">{field}</label>
            <div className="control">

                <input className="input" type="text"
                       onChange={e => onChange(e, field)} value={state[field]}/>
            </div>
        </div>
    )
}
