import React, {useState} from "react";

export default function JsonTextarea(props) {
    var test = JSON.stringify(props.value, null, '\t');
    const [innerState, setInnerState] = useState(test);
    return (
        <div>
            <label className="label">{props.name}</label>
            <textarea rows={props.size}
                      className="textarea"
                      value={innerState}
                      onBlur={e => props.onBlur(innerState, props.name)}
                      onChange={e => {
                          setInnerState(e.target.value)
                      }}
            />
        </div>

    )
}
