import Button from "react-bulma-companion/lib/Button";
import React, {useState} from "react";
import {ChannelViewBase} from "./ChannelViewBase";


export function AddChannelView(props) {
    const [edit, setEdit] = useState(false);


    const handleAddChannel = (channel) => {
        props.onChange(channel);
        setEdit(false);
    }

    const editChannel = () => {
        setEdit(true);
    };
    const cancelEdit = () => {
        setEdit(false);

    }

    return (
        <div>
            {edit ? (<ChannelViewBase {...props} onChange={handleAddChannel} onCancel={cancelEdit}/>)
                : (<Button color="success" onClick={editChannel} fullwidth
                           disabled={(props.channels.length === 0)}>Add Channel</Button>)
            }
        </div>
    )
}
