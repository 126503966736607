import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import user from './user';
import { stores } from './stores';
import { products } from './products';
import { channels } from './channels';
import { categories } from './categories';
import { services } from './services';
import { prices } from './prices';
import { proxies } from './proxies';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    stores,
    products,
    channels,
    categories,
    services,
    proxies,
    prices,
  });

export default createRootReducer;
