import React, { useEffect, useState } from 'react';
import {
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowState,
  useSortBy,
  useTable,
} from 'react-table';
import Columns from 'react-bulma-companion/lib/Columns';
import Column from 'react-bulma-companion/lib/Column';
import Level from 'react-bulma-companion/lib/Level';
import Title from 'react-bulma-companion/lib/Title';
import Field from 'react-bulma-companion/lib/Field';
import Input from 'react-bulma-companion/lib/Input';
import Control from 'react-bulma-companion/lib/Control';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import ConfirmModal from '../../organisms/ConfirmModal';
import {
  faChevronDown,
  faChevronUp,
  faRedo,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { Button as EvergreenButton } from 'evergreen-ui';
import { RefreshIcon } from 'evergreen-ui';

import GlobalFilter from './GlobalFilter';
import Button from 'react-bulma-companion/lib/Button';
import ColumnSelector from './ColumnSelector';

function Table(props) {
  const {
    columns,
    data,
    renderRowSubComponent,
    addRow,
    deleteRow,
    search,
    pagination,
    header,
    edit,
    expander,
    displayFilter,
    filterBox,
    refresh,
    handleLoadNewPage,
    handleRefresh,
    useLocalFilterAndSort,
    useColumnSelector,
  } = props;

  const [searchQuery, setSearchQuery] = useState({ global: '' });
  const [sortBy, setSortBy1] = useState([{ id: 'name', desc: true }]);
  const [loading, setLoading] = useState(false);
  const [remoteFilter, setRemoteFilter] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(Number(props.pageSize));
  const [totalPages, setTotalPages] = useState(
    props.totalPages == null
      ? Math.ceil(data.length / pageSize)
      : Number(props.totalPages)
  );
  const getQueryString = (search = null, sort = null) => {
    let sortByNew = null;
    let sortOrderNew = null;

    if (sortBy.length > 0) {
      sortByNew = sortBy[0].id;
      sortOrderNew = sortBy[0].desc ? -1 : 1;
    }
    if (sort != null) {
      sortByNew = sort.id;
      sortOrderNew = sort.desc ? -1 : 1;
    }

    let query = {
      ...remoteFilter,
      search: search == null ? searchQuery.global : search,
      sortBy: sortByNew,
      sortOrder: sortOrderNew,
    };
    Object.keys(query).forEach((k) => {
      if (query[k] == null || query[k] === '') {
        delete query[k];
      }
    });

    return query;
  };

  const handleOnRefresh = (pageSize, search, sort) => {
    setLoading(true);
    setPageSize(pageSize);
    setPageIndex(0);
    handleRefresh(pageSize, getQueryString(search, sort));
  };
  const handleOnLoadNextPage = () => {
    if (pageIndex < totalPages) {
      //load next page
      if ((pageIndex + 1) * pageSize + 1 > rows.length) {
        setLoading(true);
        handleLoadNewPage(pageIndex + 1, pageSize, getQueryString()).then(
          () => {
            setLoading(false);
            setPageIndex(Math.min(totalPages, pageIndex + 1));
          }
        );
      } else {
        setPageIndex(Math.min(totalPages, pageIndex + 1));
      }
    }
  };

  useEffect(() => {
    if (useLocalFilterAndSort) {
      setGlobalFilter(searchQuery);
    }
    setSortBy(sortBy);
    setLoading(false);
    setTotalPages(
      props.totalPages == null
        ? Math.ceil(data.length / pageSize)
        : props.totalPages
    );
  }, [data]);
  var deleterAndExpander = edit
    ? [
        {
          id: 'expander',
          Header: 'Edit',
          Cell: ({ row }) => (
            <Button
              color={row.isExpanded ? 'dark' : 'light'}
              onClick={(e) => row.toggleRowExpanded()}
              onKeyPress={(e) => row.toggleRowExpanded()}
            >
              Edit
            </Button>
          ),
        },
        {
          id: 'deleter',
          Header: 'Delete',
          Cell: ({ row }) => {
            const closeModal = () =>
              row.setState((state) => ({ ...state, confirm: false }));
            return (
              <div>
                {/* <span
                  className="icon space-right"
                  onClick={(e) => {
                    row.setState({ confirm: true });
                  }}
                  onKeyPress={(e) => row.toggleRowExpanded()}
                >
                  <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                </span> */}
                <Button
                  color="danger"
                  onClick={(e) => {
                    row.setState({ confirm: true });
                  }}
                  onKeyPress={(e) => row.toggleRowExpanded()}
                >
                  Delete
                </Button>
                <ConfirmModal
                  confirm={row.state.confirm}
                  closeModal={closeModal}
                  deleteTodo={() => {
                    deleteRow(row.original, row.index);
                    row.setState({ confirm: false });
                  }}
                />
              </div>
            );
          },
        },
      ]
    : [];

  var expanderOnly = expander
    ? [
        {
          id: 'expanderOnly',
          Header: 'Expand',
          Cell: ({ row }) => (
            <span
              className="icon space-right"
              onClick={(e) => row.toggleRowExpanded()}
              onKeyPress={(e) => row.toggleRowExpanded()}
            >
              <FontAwesomeIcon
                icon={!row.isExpanded ? faChevronDown : faChevronUp}
                size="lg"
              />
            </span>
          ),
        },
      ]
    : [];
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    allColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    rows,
    setSortBy,
    state,
  } = useTable(
    {
      columns: React.useMemo(
        () => [...columns, ...deleterAndExpander, ...expanderOnly],
        [columns]
      ),
      data,
      globalFilter: (rows, _, filter) => {
        return rows.filter((row) =>
          Object.keys(row.original).some((k) => {
            //todo this might be too expensive
            return JSON.stringify(row.original[k])
              .toLowerCase()
              .includes(filter.global.trim().toLowerCase());
          })
        );
      },
      initialState: {
        sortBy: sortBy,
        globalFilter: { global: '' },
        hiddenColumns: props.hiddenColumns,
      },

      manualSortBy: !useLocalFilterAndSort,
      initialRowStateAccessor: () => ({ confirm: false }),
      initialCellStateAccessor: () => ({ confirm: false }),
    },

    useRowState,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );
  useEffect(() => {
    if (
      state.sortBy.length !== sortBy.length ||
      (sortBy.length > 0 &&
        Object.keys(sortBy[0]).some((k) => sortBy[0][k] !== state.sortBy[0][k]))
    ) {
      setSortBy1(state.sortBy); //todo do this smarter
      if (!useLocalFilterAndSort) {
        handleOnRefresh(pageSize, null, state.sortBy[0]);
      }
    }
  }, [state.sortBy]);

  const rowElem = (row, i) => {
    prepareRow(row);
    return (
      <React.Fragment key={row.id}>
        <tr>
          {row.cells.map((cell) => {
            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
          })}
        </tr>

        {row.isExpanded ? (
          <tr>
            <td
              colSpan={visibleColumns.length}
              style={{ backgroundColor: 'whitesmoke' }}
            >
              {renderRowSubComponent(
                row.original,
                () => row.toggleRowExpanded(false),
                row.index,
                state.globalFilter
              )}
            </td>
          </tr>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <>
      <Level>
        <Level.Left>
          {/* <Level.Item>
            <Title size="5" subtitle>
              <strong>123</strong> posts
            </Title>
          </Level.Item> */}
          {search && (
            <Level.Item>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter.global}
                setGlobalFilter={(value) => {
                  setSearchQuery((state) => ({ ...state, global: value }));

                  if (useLocalFilterAndSort) {
                    setGlobalFilter((state) => {
                      return { ...state, global: value };
                    });
                  } else {
                    handleOnRefresh(pageSize, value);
                  }
                }}
              />
            </Level.Item>
          )}
        </Level.Left>
        <Level.Right>
          {/* <Level.Item>
            <strong>All</strong>
          </Level.Item>
          <Level.Item>
            <a>Published</a>
          </Level.Item>
          <Level.Item>
            <a>Drafts</a>
          </Level.Item>
          <Level.Item>
            <a>Deleted</a>
          </Level.Item> */}
          <Level.Item>
            <Level.Item>{addRow != null ? addRow() : null}</Level.Item>
          </Level.Item>
          {refresh && (
            <Level.Item>
              <EvergreenButton
                isLoading={loading || props.loading}
                onClick={() => {
                  handleOnRefresh(pageSize);
                }}
                iconBefore={!loading && !props.loading ? RefreshIcon : null}
              >
                Refresh
              </EvergreenButton>
            </Level.Item>
          )}
        </Level.Right>
      </Level>
      {/* {refresh ? (
        <Columns>
          <Column size="11"></Column>
          <Column size="1">
            <Button
              color="success"
              className={'is-pulled-right'}
              loading={loading || props.loading}
              onClick={() => {
                handleOnRefresh(pageSize);
              }}
            >
              <span className="icon space-right">
                <FontAwesomeIcon icon={faRedo} size="lg" />
              </span>
            </Button>
          </Column>
        </Columns>
      ) : null} */}
      {/* 
      {search ? (
        <Columns>
          <Column
            size="12"
            style={{ textAlign: 'right' }}
            className="has-text-left"
          >
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter.global}
              setGlobalFilter={(value) => {
                setSearchQuery((state) => ({ ...state, global: value }));

                if (useLocalFilterAndSort) {
                  setGlobalFilter((state) => {
                    return { ...state, global: value };
                  });
                } else {
                  handleOnRefresh(pageSize, value);
                }
              }}
            />
          </Column>
        </Columns>
      ) : null} */}
      {displayFilter ? (
        <Columns>
          <Column size="12" className="has-text-left">
            {filterBox(
              preGlobalFilteredRows,
              (t) => {
                setRemoteFilter(t);
              },
              pageSize
            )}
          </Column>
        </Columns>
      ) : null}
      {useColumnSelector ? (
        <Columns>
          <Column size="12" className="has-text-left">
            <ColumnSelector columns={allColumns} />
          </Column>
        </Columns>
      ) : null}

      <Columns>
        <Column size="12" className="has-text-left">
          <table {...getTableProps()} className={'table is-fullwidth'}>
            {header ? (
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (
                            <FontAwesomeIcon
                              icon={
                                column.isSortedDesc
                                  ? faChevronDown
                                  : faChevronUp
                              }
                              size="lg"
                            />
                          ) : (
                            ''
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            ) : null}

            <tbody {...getTableBodyProps()}>
              {pagination
                ? rows
                    .slice(
                      pageIndex * pageSize,
                      pageIndex * pageSize + pageSize
                    )
                    .map(rowElem)
                : rows.map(rowElem)}
            </tbody>
          </table>
        </Column>
      </Columns>

      {pagination ? (
        <Columns>
          <Column size="12" className="has-text-left">
            <nav className={'level'}>
              <div className="level-left">
                <div className="level-item">
                  <div className={'buttons'}>
                    <button
                      className={'button'}
                      onClick={() => setPageIndex(Math.max(0, pageIndex - 1))}
                      disabled={pageIndex <= 0}
                    >
                      {'<'}
                    </button>{' '}
                    <button
                      className={'button'}
                      onClick={handleOnLoadNextPage}
                      disabled={pageIndex >= totalPages - 1}
                    >
                      {'>'}
                    </button>
                  </div>
                </div>
              </div>
              <div className="level-item">
                <strong>
                  Page {pageIndex + 1} of {totalPages}
                </strong>
              </div>
              <div className="level-right">
                <div className={'level-item'}>
                  <div className="select">
                    <select
                      value={pageSize}
                      onChange={(e) => {
                        const newPageSize = Number(e.target.value);
                        if (!useLocalFilterAndSort) {
                          handleOnRefresh(newPageSize);
                        } else {
                          setPageSize(newPageSize);
                          setTotalPages(Math.ceil(data.length / newPageSize));
                        }
                      }}
                    >
                      {[1, 2, 5, 10, 25, 50, 100, 200].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </nav>
          </Column>
        </Columns>
      ) : null}
    </>
  );
}

Table.defaultProps = {
  filter: { global: '', column: {} },
  search: true,
  pagination: true,
  totalPages: null,
  pageSize: 50,
  expander: false,
  header: true,
  edit: true,
  refresh: false,
  useLocalFilterAndSort: true,
  useColumnSelector: false,
  handleLoadNewPage: () => null,
  handleRefresh: () => null,
  hiddenColumns: [],
};
export default Table;
