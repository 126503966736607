import Column from "react-bulma-companion/lib/Column";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";

import React, {useEffect, useState} from "react";

export default function ColumnSelector(props) {

    const [expand, setExpand] = useState(false);
    return <>
        <div className="filter-box box">
            <article className="media">
                <div className="media-content">
                    {expand ?
                        <div className="content">
                            <ul className="checkbox-grid" style={{"marginLeft": 0}}>
                                {props.columns.map(column => (
                                    <li key={column.id}>
                                        <label>
                                            <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                                            {column.id}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        : <div onClick={() => setExpand(!expand)}><label className="label">{"Columns"}</label>

                        </div>}
                </div>
                <a className={"media-right"} onClick={() => setExpand(!expand)}>
                    <FontAwesomeIcon icon={!expand ? faChevronDown : faChevronUp} size="lg"/>
                </a>
            </article>
        </div>
    </>
}
