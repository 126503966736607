import React from 'react';


import Section from 'react-bulma-companion/lib/Section';
import Login from "../../organisms/Login";

export default function LoginSection() {
  return (
    <Section className="login-section">
      <Login />
    </Section>
  );
}
