import React from 'react'


import Table from "../../molecules/Table/Table";
import {StoreViewBase} from "../../molecules/Product/StoreViewBase";
import {AddStoreView} from "../../molecules/Product/AddStoreView";
import Select from "../../atoms/Select/Select";


function ProductStoreTable(props) {

    const {ogStores, stores, onAddStore, onChangeStore, onDeleteStore} = props;

    const columns = [
        {
            Header: "Stores",
            columns: [
                {
                    Header: 'Active',
                    accessor: 'active',
                    Cell: ({cell}) => {
                        return (<>
                            <Select label={false} state={cell.row.original} onChange={(e, field) => {
                                var newState = {...cell.row.original};
                                newState[field] = e.target.value;
                                onChangeStore(cell.row.index)(newState);
                            }} field={"active"} options={["active", "test", "off"]}/>
                        </>)
                    }
                },
                {

                    Header: 'Store',
                    accessor: (row) => (row.store != null) ? row.store.name : <p style={{color:"red"}}>Store unspecified/deleted</p>,

                },

                {
                    Header: 'Url',
                    accessor: 'url',
                },
                {
                    Header: 'Affiliate Url',
                    accessor: 'affiliateUrl',
                }
            ],
        },
    ]

    const renderRowSubComponent = (stores) => React.useCallback(
        (store, onCancel, index) => (
            <StoreViewBase
                store={store}
                stores={stores}
                onChange={onChangeStore(index)}

                onCancel={onCancel}
            />
        ),
        []
    )
    const addRow = React.useCallback(() => (<AddStoreView stores={ogStores} onChange={onAddStore}/>), [ogStores]);

    return (

        <Table  search={false} pagination={false} columns={columns} data={stores}
               renderRowSubComponent={renderRowSubComponent(ogStores)} addRow={addRow}
               deleteRow={onDeleteStore}/>

    )
}

export default ProductStoreTable
