import React, {useState} from 'react';
import {useDispatch} from 'react-redux';


import {attemptAddService} from "../../../store/thunks/services";
import Button from "react-bulma-companion/lib/Button";
import Service from "../Service/Service";


export default function AddService() {
    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false);


    const editService = () => setEdit(true);

    const handleAddService = (service) => {
        dispatch(attemptAddService(service))
            .then(() => {
                setEdit(false);
            });
    };
    const onCancel = () => {
        setEdit(false);
    }

    return (
        <div>
            {!edit ? (<Button color="success" onClick={editService} fullwidth>Add</Button>) : (
                <Service onChange={handleAddService} onCancel={onCancel}/>
            )
            }
        </div>


    );
}

