import {createAction} from '@reduxjs/toolkit'

export const addService = createAction('services/add', function prepare(service) {
    return {
        payload: {
            service,
        },
    }
})

export const removeService = createAction('services/delete', function prepare(service) {
    return {
        payload: {
            service,
        },
    }
})

export const setServices = createAction('services/set', function prepare(services) {
    return {
        payload: {
            services,
        },
    }
})
export const setService = createAction('service/set', function prepare(service) {
    return {
        payload: {
            service,
        },
    }
})
