import React from 'react'


import {Chart} from 'react-charts'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";

export default function BarChart({data}) {
    const [expand, setExpand] = React.useState(false);

    const series = React.useMemo(
        () => ({
            type: "bar"
        }),
        []
    );
    const axes = React.useMemo(
        () => [
            {primary: true, type: "ordinal", position: "bottom"},
            {position: "left", type: "linear"}
        ],
        []
    );
    return (
        <>
            <div className="filter-box box">
                <article className="media">
                    <div className="media-content">
                        {expand ?
                            <div className="content">
                                <Chart style={{
                                    width: '100%',
                                    height: '400px'
                                }} data={data} series={series} axes={axes} tooltip/>

                            </div> :
                            <div onClick={() => setExpand(!expand)}><label className="label">{"Stats"}</label></div>}
                    </div>
                    <a className={"media-right"} onClick={() => setExpand(!expand)}>
                        <FontAwesomeIcon icon={!expand ? faChevronDown : faChevronUp} size="lg"/>
                    </a>
                </article>
            </div>

        </>
    )
}
