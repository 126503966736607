import {  createReducer } from '@reduxjs/toolkit'
import {addStore, removeStore, setStore, setStores} from "../actions/stores";



const initialState = []

export  const stores = createReducer(initialState, (builder) => {
    builder
        .addCase(addStore, (state, action) => {
           state.push(action.payload.store);
           return state;
        })
        .addCase(removeStore, (state, action) => {
            return state.filter(el => el._id !== action.payload.store._id);
        })
        .addCase(setStores, (state, action) => {
           return action.payload.stores;
        })
        .addCase(setStore, (state, action) => {

            return state.map(store => (action.payload.store._id === store._id)? action.payload.store: store);
        })

})
