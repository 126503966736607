import React, { useState } from 'react';

import { WithContext as ReactTags } from 'react-tag-input';

export default function TagInput(props) {
  const { state, label, minQueryLength, onChange } = props;
  const suggestions = props.suggestions.map((s) => ({ ...s, id: s._id }));
  const [tags, setTags] = useState(state.map((s) => ({ ...s, id: s._id })));
  const handleDelete = (i) => {
    let newTags = tags.filter((tag, index) => index !== i);
    setTags(newTags);
    onChange(newTags);
  };
  const handleAddition = (newTag) => {
    if (suggestions.includes(newTag)) {
      setTags((tags) => [...tags, newTag]);
      onChange([...tags, newTag]);
    }
  };

  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <ReactTags
          classNames={{
            tags: 'buttons',
            tagInput: 'tagInputClass',
            tagInputField: 'input',
            selected: 'selectedClass',
            tag: 'button',
            remove: 'removeClass',
          }}
          labelField={'name'}
          allowDeleteFromEmptyInput={false}
          allowUnique={true}
          allowDragDrop={false}
          tags={tags}
          autocomplete={true}
          suggestions={suggestions}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          minQueryLength={minQueryLength !== undefined ? minQueryLength : 2}
          renderSuggestion={(props, query) => {
            return <div>{props.name}</div>;
          }}
        />
      </div>
    </div>
  );
}
