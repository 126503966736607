import { createReducer } from '@reduxjs/toolkit';
import {
  addProxy,
  removeProxy,
  setProxy,
  setProxies,
} from '../actions/proxies';

const initialState = [];

export const proxies = createReducer(initialState, (builder) => {
  builder
    .addCase(addProxy, (state, action) => {
      state.push(action.payload.proxy);
      return state;
    })
    .addCase(removeProxy, (state, action) => {
      return state.filter((el) => el._id !== action.payload.proxy._id);
    })
    .addCase(setProxies, (state, action) => {
      return action.payload.proxies;
    })
    .addCase(setProxy, (state, action) => {
      return state.map((proxy) =>
        action.payload.proxy._id === proxy._id ? action.payload.proxy : proxy
      );
    });
});
