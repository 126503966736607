import React from 'react';
import { useSelector } from 'react-redux';
import R from 'ramda';

import { useDispatch } from 'react-redux';
import Table from '../../molecules/Table/Table';

import { attemptGetServices } from '../../../store/thunks/services';
import { StatusEnum } from '../../../utils/transformation';
import {
  faEllipsisH,
  faExclamationCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Service from '../../molecules/Service/Service';
import {
  attemptDeleteService,
  attemptUpdateService,
} from '../../../store/thunks/services';
import AddChannel from '../../molecules/AddChannel/AddChannel';
import AddService from '../../molecules/AddService/AddService';

function ProxyTable({ loading }) {
  const dispatch = useDispatch();
  const deleteService = (service) => dispatch(attemptDeleteService(service));
  const updateService = (service) => dispatch(attemptUpdateService(service));

  const columns = [
    {
      Header: 'Service',
      columns: [
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Secret',
          accessor: 'secret',
        },
      ],
    },

    {
      Header: 'Status',
      columns: [
        {
          Header: 'Type',
          accessor: (row) => (row.status != null ? row.status.type : null),
        },
        {
          Header: 'Description',
          accessor: (row) =>
            row.status != null ? JSON.stringify(row.status.description) : null,
        },
        {
          Header: 'Timestamp',
          accessor: (row) =>
            row.status != null && row.status.timestamp != null
              ? new Date(row.status.timestamp).toLocaleString()
              : null,
        },
      ],
    },
    {
      Header: 'Connection',
      columns: [
        {
          Header: 'Message',
          accessor: 'connection',
        },

        {
          Header: 'State',
          Cell: ({ row }) => {
            let status = StatusEnum.error;
            let icon = faEllipsisH;
            const connectionStatus = row.original.connection;
            if (connectionStatus === 'connected') {
              status = StatusEnum.warning;
            } else if (connectionStatus === 'lost connection') {
              status = StatusEnum.error;
            } else if (connectionStatus === 'responsive') {
              status = StatusEnum.ok;
            }

            if (status === StatusEnum.warning) icon = faExclamationCircle;
            else if (status === StatusEnum.error) icon = faTimes;
            else if (status === StatusEnum.ok) icon = faCheckCircle;
            return (
              <>
                <span className="icon space-right">
                  <FontAwesomeIcon icon={icon} size="lg" />
                </span>
              </>
            );
          },
        },
      ],
    },
  ];

  const { services } = useSelector(R.pick(['services']));

  const renderRowSubComponent = React.useCallback(
    (service, onCancel) => (
      <Service
        key={service._id}
        service={service}
        onChange={updateService}
        onCancel={onCancel}
      />
    ),
    []
  );
  const addRow = React.useCallback(() => <AddService />, []);
  return (
    <Table
      columns={columns}
      data={services}
      renderRowSubComponent={renderRowSubComponent}
      loading={loading}
      deleteRow={deleteService}
      refresh={true}
      addRow={addRow}
      handleRefresh={(_) => dispatch(attemptGetServices())}
    />
  );
}

export default ProxyTable;
