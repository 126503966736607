import request from 'superagent';
import {handleError, handleSuccess} from "../utils/api";

export const postCategory = info =>
    request.post('/api/Categories')
        .send(info)
        .then(handleSuccess)
        .catch(handleError);

export const getCategories = () =>
    request.get('/api/Categories')
        .then(handleSuccess)
        .catch(handleError);

export const putCategory= info =>
    request.put('/api/Categories')
        .send(info)
        .then(handleSuccess)
        .catch(handleError);

export const deleteCategory = info =>
    request.delete('/api/Categories/'+info._id + "/")
        .then(handleSuccess)
        .catch(handleError);
