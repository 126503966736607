import React from "react";

export default function Select(props) {
    const {field, state, onChange, options, label} = props;

    return (
        <div className="field">
            {label ? <label className="label">{field}</label> : null}
            <div className="control">
                <div className="select">
                    <select value={state[field]} onChange={e => onChange(e, field)}>
                        {options.map(option => <option key={option} value={option}>{option}</option>)}

                    </select>
                </div>
            </div>
        </div>
    )

}

Select.defaultProps = {
    label: true
};
