import {createAction} from '@reduxjs/toolkit'

export const addStore = createAction('stores/add', function prepare(store) {
    return {
        payload: {
            store,
        },
    }
})

export const removeStore = createAction('stores/delete', function prepare(store) {
    return {
        payload: {
            store,
        },
    }
})

export const setStores = createAction('stores/set', function prepare(stores) {
    return {
        payload: {
            stores,
        },
    }
})
export const setStore = createAction('store/set', function prepare(store) {
    return {
        payload: {
            store,
        },
    }
})
