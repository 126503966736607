import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import R from "ramda";
import Table from "../../molecules/Table/Table";

import {attemptDeleteCategory, attemptGetCategories, attemptUpdateCategory} from "../../../store/thunks/categories";
import AddCategory from "../../molecules/AddCategory/AddCategory";
import Category from "../../molecules/Category/Category";
import Select from "../../atoms/Select/Select";


function CategoryTable({loading}) {
    const dispatch = useDispatch();

    const deleteCategory = (category) => dispatch(attemptDeleteCategory(category));
    const updateCategory = (category) => dispatch(attemptUpdateCategory(category));

    const onChange = (e, store, field) => {
        var newState = Object.assign({...store});
        newState[field] = e.target.value;
        dispatch(attemptUpdateCategory(newState));
    }

    const columns = [
        {
            Header: "Categories",
            columns: [
                {
                    Header: 'Keepa Scraper',
                    accessor: 'active',
                    Cell: ({cell}) => {
                        return (<>
                            <Select label={false} state={cell.row.original} onChange={(e, field) => {
                                onChange(e, cell.row.original, field);
                            }} field={"active"} options={["active", "off"]}/>

                        </>)
                    }
                },
                {
                    Header: 'Name',
                    accessor: 'name',
                },
                {
                    Header: 'CategoryId',
                    accessor: 'categoryId',
                },


                {
                    Header: 'Last Search',
                    accessor: 'lastSearch',

                }
            ],
        },
    ]

    const {categories} = useSelector(R.pick(['categories']));

    const renderRowSubComponent = React.useCallback(
        (category, onCancel) => (

            <Category key={category._id} category={category} onChange={updateCategory} onCancel={onCancel}/>
        ),
        []
    )
    const addRow = React.useCallback(() => (<AddCategory/>), []);

    return (

        <Table columns={columns} data={categories} loading={loading}
               renderRowSubComponent={renderRowSubComponent} addRow={addRow}
               deleteRow={deleteCategory} refresh={true} handleRefresh={(_) => dispatch(attemptGetCategories())}/>

    )
}

export default CategoryTable
