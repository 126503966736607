import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import R from 'ramda';

import { attemptGetProducts } from '../../../store/thunks/products';
import { attemptGetStores } from '../../../store/thunks/stores';
import { attemptGetChannels } from '../../../store/thunks/channels';
import { attemptGetCategories } from '../../../store/thunks/categories';
import ProductTable from '../../organisms/ProductTable/ProductTable';
import DefaultSection from '../../templates/DefaultSection/DefaultSection';

export default function ProductPage() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push('/login'));
    } else {
      dispatch(attemptGetProducts(0, 50)).then(() => setLoading(false));

      dispatch(attemptGetStores());
      dispatch(attemptGetChannels());
      dispatch(attemptGetCategories());
    }
  }, []);

  return (
    <div className="todo-page page">
      <DefaultSection title={'Products'}>
        <ProductTable loading={loading} />
      </DefaultSection>
    </div>
  );
}
