import {createReducer} from '@reduxjs/toolkit'
import {addService, removeService, setService, setServices} from "../actions/services";


const initialState = []

export const services = createReducer(initialState, (builder) => {
    builder
        .addCase(addService, (state, action) => {
            state.push(action.payload.service);
            return state;
        })
        .addCase(removeService, (state, action) => {
            return state.filter(el => el._id !== action.payload.service._id);
        })
        .addCase(setServices, (state, action) => {
            return action.payload.services;
        })
        .addCase(setService, (state, action) => {

            return state.map(service => (action.payload.service._id === service._id) ? action.payload.service : service);
        })

})
