import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import R from 'ramda';

import { attemptGetProxies } from '../../../store/thunks/proxies';

import ProxyTable from '../../organisms/ProxyTable/ProxyTable';
import DefaultSection from '../../templates/DefaultSection/DefaultSection';

export default function ProxyPage() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push('/login'));
    } else {
      dispatch(attemptGetProxies()).then(() => setLoading(false));
    }
  }, []);

  return (
    <div className="todo-page page">
      <DefaultSection title={'Proxies'}>
        <ProxyTable loading={loading} />
      </DefaultSection>
    </div>
  );
}
