import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import ReactNotification from 'react-notifications-component';
import { useDispatch } from 'react-redux';
import R from 'ramda';

import StorePage from '../../pages/StorePage';
import ProductPage from '../../pages/ProductPage/ProductPage';
import ChannelPage from '../../pages/ChannelPage/ChannelPage';
import WelcomePage from '../../pages/WelcomePage';
import Navigation from '../../organisms/Navigation';
import LoginPage from '../../pages/LoginPage';
import RegisterPage from '../../pages/RegisterPage';

import HomePage from '../../pages/HomePage';
import SettingsPage from '../../pages/SettingsPage';
import LostPage from '../../pages/LostPage';
import Footer from '../../organisms/Footer';
import { attemptGetUser } from '../../../store/thunks/user';
import StatusPage from '../../pages/StatusPage/StatusPage';
import RecoveryPage from '../../pages/RecoveryPage/RecoveryPage';
import ProposalPage from '../../pages/ProposalPage';
import CategoryPage from '../../pages/CategoryPage/CategoryPage';
import ServicePage from '../../pages/ServicePage/ServicePage';
import ProxyPage from '../../pages/ProxyPage/ProxyPage';
import LegoPage from '../../pages/LegoPage/LegoPage';
import AnalyticsPage from '../../pages/AnalyticsPage/AnalyticsPage';

export default function Main({ location }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(attemptGetUser())
      .then(() => setLoading(false))
      .catch(R.identity);
  }, []);

  useEffect(() => {
    console.log(location.pathname);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    !loading && (
      <div>
        <ReactNotification />
        {location.pathname !== '/' && location.pathname !== '/login' && (
          <Navigation pathname={location.pathname} />
        )}

        <div className="main">
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route path="/login" component={LoginPage} />
             <Route path="/register" component={RegisterPage} /> 
            {/* <Route path="/home" component={HomePage} /> */}
            <Route path="/status" component={StatusPage} />
            <Route path="/store" component={StorePage} />
            <Route path="/product" component={ProductPage} />
            <Route path="/proposal" component={ProposalPage} />
            <Route path="/analytics" component={AnalyticsPage} />
            <Route path="/category" component={CategoryPage} />

            <Route path="/channel" component={ChannelPage} />
            <Route path="/lego" component={LegoPage} />
            <Route path="/settings" component={SettingsPage} />
            <Route path="/service" component={ServicePage} />
            <Route path="/proxy" component={ProxyPage} />

            <Route path="/recovery" component={RecoveryPage} />
            <Route path="*" component={LostPage} />
          </Switch>
        </div>
        {/* <Footer /> */}
      </div>
    )
  );
}

Main.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};
