import React from 'react';

export default function InputWithButton(props) {
  const { onChange, state, field, onClick, buttonTitle } = props;
  return (
    <div className="field">
      {/* <label className="label">{field}</label> */}

      <div>
        <div className="field has-addons">
          <div className="control is-expanded">
            <input
              className="input"
              type="text"
              onKeyPress={props.onKeyPress}
              onChange={(e) => onChange(e, field)}
              value={state[field]}
            />
          </div>
          <div className="control">
            <a className="button" onClick={onClick}>
              {buttonTitle}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
