import R from 'ramda';

export const capitalize = string => R.concat(R.toUpper(R.head(string)), R.tail(string));

export const timeDuration = time => {
    if (isNaN(time)) {
        return "NaN";
    }

    return new Date(time).toISOString().slice(11, -1);

}

export const generatePassword = () => {
  return   Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2)
}
