import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import R from 'ramda';
import { capitalize } from '../../../utils/strings';
import Navbar from 'react-bulma-companion/lib/Navbar';
import Container from 'react-bulma-companion/lib/Container';
import Image from 'react-bulma-companion/lib/Image';
import Title from 'react-bulma-companion/lib/Title';
import UserDropdown from '../../molecules/UserDropdown';

export default function Navigation({ pathname }) {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));

  const [auth, setAuth] = useState(!R.isEmpty(user));
  const [open, setOpen] = useState(false);
  const [isActive, setisActive] = useState(false);
  useEffect(() => {
    setAuth(!R.isEmpty(user));
  }, [user.username]);

  const toggleDropdown = () => setOpen(!open);

  const closeDropdown = () => setOpen(false);
  const isPage = (name) =>
    pathname === '/' + name || pathname === '/' + name + '/';
  const pages = [
    // 'home',
    'status',
    'store',
    'product',
    'channel',
    'category',
    'proposal',
    'proxy',
    'lego',
    // 'service',
  ];
  return (
    <Navbar fixed="top" color="link">
      <Container>
        <Navbar.Brand>
          <Navbar.Item
            onClick={() => dispatch(push(auth ? '/status' : '/'))}
            aria-label="main navigation"
            link
          >
            <Title size="4">RH Monitor</Title>
          </Navbar.Item>
          <div className="navbar-brand-right">
            {!auth && (
              <Navbar.Item
                className="is-hidden-desktop"
                onClick={() => dispatch(push('/login'))}
                link
              >
                <Title size="6">Login</Title>
              </Navbar.Item>
            )}
          </div>
          {auth && (
            <Navbar.Burger
              className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
              onClick={() => {
                setisActive(!isActive);
              }}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </Navbar.Burger>
          )}
        </Navbar.Brand>

        {auth ? (
          <Navbar.Menu className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
            <Navbar.Start>
              {/* <Navbar.Item hasDropdown hoverable>
                <Navbar.Link>More</Navbar.Link>
                <Navbar.Dropdown>
                  <Navbar.Item>About</Navbar.Item>
                  <Navbar.Item>Jobs</Navbar.Item>
                  <Navbar.Item>Contact</Navbar.Item>
                  <Navbar.Divider />
                  <Navbar.Item>Report an issue</Navbar.Item>
                </Navbar.Dropdown>
              </Navbar.Item> */}
              {pages.map((page) => (
                <Navbar.Item
                  key={page}
                  className={'navbar-item'}
                  onClick={() => dispatch(push(`/${page}`))}
                  active={isPage(page)}
                  tab
                  link
                >
                  <Title size="6">{capitalize(page)}</Title>
                </Navbar.Item>
              ))}
            </Navbar.Start>
            <Navbar.End>
              <Navbar.Item
                onClick={toggleDropdown}
                onKeyPress={toggleDropdown}
                hoverable
                link
              >
                <Image size="32x32">
                  <Image.Content
                    className="profile-img"
                    src={user.profilePic || '/images/default-profile.png'}
                  />
                </Image>
                <span className="dropdown-caret" />
              </Navbar.Item>
            </Navbar.End>
          </Navbar.Menu>
        ) : (
          <Navbar.Menu>
            <Navbar.End>
              <Navbar.Item onClick={() => dispatch(push('/login'))} link>
                <Title size="6">Login</Title>
              </Navbar.Item>
              {/*<Navbar.Item onClick={() => dispatch(push('/register'))} link>*/}
              {/*    <Button color="success">Sign Up</Button>*/}
              {/*</Navbar.Item>*/}
            </Navbar.End>
          </Navbar.Menu>
        )}
        <UserDropdown open={open} closeDropdown={closeDropdown} />
      </Container>
    </Navbar>
  );
}

Navigation.propTypes = {
  pathname: PropTypes.string.isRequired,
};
