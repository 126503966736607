import React from 'react'
import {useSelector} from "react-redux";
import R from "ramda";

import {
    attemptDeleteProduct,
    attemptGetProductPage,
    attemptGetProducts,
    attemptUpdateProduct
} from "../../../store/thunks/products";
import {useDispatch} from 'react-redux';
import AddProduct from "../../molecules/AddProduct";
import Table from "../../molecules/Table/Table";
import Product from "../../molecules/Product/Product";
import {ProductFilter} from "../../molecules/ProductFilter/ProductFilter";

;


function ProductTable( {loading}) {
    const dispatch = useDispatch();

    const deleteProduct = (product) => dispatch(attemptDeleteProduct(product));
    const updateProduct = (product) => dispatch(attemptUpdateProduct(product));


    const columns = [
        {
            Header: "Products",
            columns: [
                {
                    Header: 'Name',
                    accessor: 'name',
                },
                {
                    Header: 'Categories',
                    disableSortBy: true,
                    accessor: (row) => {
                        return row.categories.map(c => c.name);
                    },
                    Cell: ({row}) => {
                        return (<div>{row.original.categories.map(c => (
                            <span key={c._id} className={"tag is-link"}>{c.name}</span>))}</div>)
                    }
                },
                {
                    Header: 'Model',
                    accessor: 'model',
                },
                {
                    Header: 'Max Price',
                    accessor: 'maxPrice',
                },
                {
                    Header: 'Profit Estimates',
                    accessor: 'profitEstimates',
                }
            ],
        },
    ]

    const {products, total, pageSize} = useSelector(R.pick(['products'])).products;
    const {stores} = useSelector(R.pick(['stores']));
    const {channels} = useSelector(R.pick(['channels']));
    const {categories} = useSelector(R.pick(['categories']));
    const renderFilterBox = React.useCallback(
        (preGlobalFilteredRows, filterCallback, pageSize) => (
            <ProductFilter
                stores={stores}
                categories={categories}
                preGlobalFilteredRows={preGlobalFilteredRows}
                handleRefresh={(filter) => dispatch(attemptGetProducts(0, pageSize, filter))}
                initFilter={{
                    productActive: "all",
                    storeActive: "all",
                    categoryActive: "all",
                    disableStoreFilter: true,
                    disableCategoriesFilter: true
                }}
                filterCallback={filterCallback}
            />
        ),
        [stores, categories]
    )

    const renderRowSubComponent = React.useCallback(
        (product, onCancel) => (

            <Product key={product._id} onCancel={onCancel} onChange={updateProduct} product={product}
                     channels={channels} categories={categories} stores={stores}/>
        ),
        [stores, channels, categories]
    )
    const addRow = React.useCallback(() => (
        <AddProduct stores={stores} channels={channels} categories={categories}/>), [stores, channels, categories]);

    return (

        <Table columns={columns} data={products} filterBox={renderFilterBox} loading={loading}
               renderRowSubComponent={renderRowSubComponent} addRow={addRow} displayFilter={true}
               deleteRow={deleteProduct} refresh={true} totalPages={total} pageSize={pageSize}
               handleLoadNewPage={(page, pageSize, query) => (dispatch(attemptGetProductPage(page, pageSize, query)))}
               handleRefresh={(pageSize, query) => dispatch(attemptGetProducts(0, pageSize, query))}
               useLocalFilterAndSort={false}/>

    )
}

export default ProductTable
