import {createReducer} from '@reduxjs/toolkit'
import {addCategory, removeCategory, setCategory, setCategories} from "../actions/categories";


const initialState = []

export const categories = createReducer(initialState, (builder) => {
    builder
        .addCase(addCategory, (state, action) => {
            state.push(action.payload.category);
            return state;
        })
        .addCase(removeCategory, (state, action) => {
            return state.filter(el => el._id !== action.payload.category._id);
        })
        .addCase(setCategories, (state, action) => {
            return action.payload.categories;
        })
        .addCase(setCategory, (state, action) => {

            return state.map(category => (action.payload.category._id === category._id) ? action.payload.category : category);
        })

})
