import {createAction} from '@reduxjs/toolkit'

export const addCategory = createAction('categories/add', function prepare(category) {
    return {
        payload: {
            category,
        },
    }
})

export const removeCategory = createAction('categories/delete', function prepare(category) {
    return {
        payload: {
            category,
        },
    }
})

export const setCategories = createAction('categories/set', function prepare(categories) {
    return {
        payload: {
            categories,
        },
    }
})
export const setCategory = createAction('category/set', function prepare(category) {
    return {
        payload: {
            category,
        },
    }
})
