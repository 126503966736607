import React from 'react';
import Container from "react-bulma-companion/lib/Container";
import Title from "react-bulma-companion/lib/Title";
import Section from "react-bulma-companion/lib/Section";

export default function RecoveryPage() {
  return (
    <div className="lost-page page">
        <Section className="lost-section">
            <Container>
                <Title size="1">
                    Lol
                </Title>
                <p>
                    Too bad.
                </p>
            </Container>
        </Section>
    </div>
  );
}
