import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import R from 'ramda';

import {attemptGetPrices} from "../../../store/thunks/prices";
import PriceTable from "../../organisms/PriceTable/PriceTable";
import DefaultSection from "../../templates/DefaultSection/DefaultSection";
import {load} from "cheerio";

export default function PricePage() {
    const dispatch = useDispatch();
    const {user} = useSelector(R.pick(['user']));

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (R.isEmpty(user)) {
            dispatch(push('/login'));
        } else {
            dispatch(attemptGetPrices(0, 50))
                .then(() => setLoading(false));
        }
    }, []);

    return(
        <div className="todo-page page">
            <DefaultSection title={"Prices"}><PriceTable loading={loading}/></DefaultSection>
        </div>
    );
}
