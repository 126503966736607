import React, { useState } from 'react';
import Buttons from 'react-bulma-companion/lib/Buttons';
import Button from 'react-bulma-companion/lib/Button';

import Input from '../../atoms/Input/Input';
import JsonTextarea from '../../atoms/JsonTextarea/JsonTextarea';

export default function Proxy(props) {
  const { inDialog } = props;
  const [state, setState] = useState(props.proxy);
  const onChange = (e, key) => {
    const { value } = e.target;
    setState((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const onChangeJson = (e, key) => {
    var newState = Object.assign({ ...state });
    if (e === '') {
      newState[key] = {};
    } else {
      newState[key] = JSON.parse(e);
    }
    setState(newState);
  };

  const handleUpdateProxy = () => {
    props.onChange(state);
  };
  const cancelEdit = () => {
    props.onCancel();
    setState(props.proxy);
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: inDialog === true ? '0px' : '20px',
        marginBottom: inDialog === true ? '20px' : '0px',
      }}
    >
      <div className="content">
        <div>
          <Input state={state} onChange={onChange} field={'name'} />
          <JsonTextarea
            name={'urls'}
            size={25}
            value={state.urls}
            onBlur={onChangeJson}
          />
        </div>
      </div>

      <nav className="level is-mobile">
        <div className="level-left"></div>
        <div className="level-right">
          <Buttons>
            <Button color="success" onClick={handleUpdateProxy}>
              Save Changes
            </Button>
            <Button color="danger" onClick={cancelEdit}>
              Cancel
            </Button>
          </Buttons>
        </div>
      </nav>
    </div>
  );
}

Proxy.defaultProps = {
  proxy: {
    name: '',
    urls: [],
  },
};
