import Button from "react-bulma-companion/lib/Button";
import React, {useState} from "react";
import {StoreViewBase} from "./StoreViewBase";


export function AddStoreView(props) {
    const [edit, setEdit] = useState(false);


    const handleAddStore = (store) => {
        props.onChange(store);
        setEdit(false);
    }

    const editStore = () => {
        setEdit(true);
    };
    const cancelEdit = () => {
        setEdit(false);

    }

    return (
        <div>
            {edit ? (<StoreViewBase {...props} onChange={handleAddStore} onCancel={cancelEdit}/>)
                : (<Button color="success" onClick={editStore} fullwidth
                           disabled={(props.stores.length === 0)}>Add Store</Button>)
            }
        </div>
    )
}
