import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Buttons from 'react-bulma-companion/lib/Buttons';
import Button from 'react-bulma-companion/lib/Button';

import Input from '../../atoms/Input/Input';
import Select from '../../atoms/Select/Select';

export default function Category(props) {
  const { inDialog } = props;
  const [state, setState] = useState(props.category);
  const onChange = (e, key) => {
    const { value } = e.target;
    setState((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const handleUpdateChannel = () => {
    props.onChange(state);
  };
  const cancelEdit = () => {
    props.onCancel();
    setState(props.category);
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: inDialog === true ? '0px' : '20px',
      }}
    >
      <div className="content">
        <div>
          <Input state={state} onChange={onChange} field={'name'} />
          <Input state={state} onChange={onChange} field={'categoryId'} />
          <Select
            state={state}
            onChange={onChange}
            field={'active'}
            options={['active', 'off']}
          />
        </div>
      </div>

      <nav className="level is-mobile">
        <div className="level-left"></div>
        <div className="level-right">
          <Buttons>
            <Button color="success" onClick={handleUpdateChannel}>
              Save Changes
            </Button>
            <Button color="danger" onClick={cancelEdit}>
              Cancel
            </Button>
          </Buttons>
        </div>
      </nav>
    </div>
  );
}

Category.defaultProps = {
  category: {
    name: '',
    active: 'active',
    categoryId: 0,
  },
};
