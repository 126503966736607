import { useAsyncDebounce } from 'react-table';
import React from 'react';
import InputWithButton from '../../atoms/InputWithButton/InputWithButton';

export default function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = React.useState(globalFilter);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setGlobalFilter(value || '');
    }
  };
  return (
    <div>
      <InputWithButton
        state={{ search: value }}
        onChange={(e) => setValue(e.target.value)}
        onClick={() => setGlobalFilter(value || '')}
        onKeyPress={handleKeyPress}
        buttonTitle={'Suchen'}
      />
    </div>
  );
}
