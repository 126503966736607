import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import R from 'ramda';

import { attemptGetStores } from '../../../store/thunks/stores';
import { attemptGetProxies } from '../../../store/thunks/proxies';
import StoreTable from '../../organisms/StoreTable/StoreTable';
import DefaultSection from '../../templates/DefaultSection/DefaultSection';

export default function StorePage() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push('/login'));
    } else {
      dispatch(attemptGetStores()).then(() => setLoading(false));
      dispatch(attemptGetProxies());
    }
  }, []);

  return (
    <div className="todo-page page">
      <DefaultSection title={'Shops'}>
        <StoreTable loading={loading} />
      </DefaultSection>
    </div>
  );
}
