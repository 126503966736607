import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Buttons from 'react-bulma-companion/lib/Buttons';
import Button from 'react-bulma-companion/lib/Button';

import Input from '../../atoms/Input/Input';
import Select from '../../atoms/Select/Select';
import JsonTextarea from '../../atoms/JsonTextarea/JsonTextarea';
import { dispatchError } from '../../../utils/api';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import TagInput from '../../atoms/TagInput/TagInput';
export default function Store(props) {
  const { proxies, inDialog } = props;

  const [state, setState] = useState(props.store);
  const onChange = (e, key) => {
    const { value } = e.target;
    setState((state) => ({
      ...state,
      [key]: value,
    }));
  };
  const toggleOnChange = (key) => {
    setState((state) => ({
      ...state,
      [key]: !state[key],
    }));
  };
  const onChangeJson = (e, key) => {
    var newState = Object.assign({ ...state });
    if (e === '') {
      newState[key] = {};
    } else {
      try {
        newState[key] = JSON.parse(e);
      } catch (e) {
        if (e instanceof SyntaxError) {
          dispatchError()({
            status: e.statusCode,
            body: { message: `In ${key}: ${e.toString()}` },
          });
        }
      }
    }
    setState(newState);
  };
  const handleUpdateStore = () => {
    props.onChange(state);
  };
  const cancelEdit = () => {
    props.onCancel();
    setState(props.store);
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: inDialog === true ? '0px' : '20px',
      }}
    >
      <div className="content">
        <div>
          <Input state={state} onChange={onChange} field={'name'} />
          <Input state={state} onChange={onChange} field={'timeout'} />
          <Input state={state} onChange={onChange} field={'pageBackoffMin'} />
          <Input state={state} onChange={onChange} field={'pageBackoffMax'} />
          <Input state={state} onChange={onChange} field={'pageSleepMin'} />
          <Input state={state} onChange={onChange} field={'pageSleepMax'} />
          <TagInput
            label={'Proxies'}
            state={state.proxies || []}
            suggestions={proxies}
            onChange={(v) => setState((state) => ({ ...state, proxies: v }))}
            minQueryLength={0}
          />
          <Checkbox
            checked={state.dumpHtml}
            field={'Dump Html'}
            onChange={() => toggleOnChange('dumpHtml')}
          />
          <Checkbox
            checked={state.ignoreOutOfStock}
            field={'Ignore Out Of Stock'}
            onChange={() => toggleOnChange('ignoreOutOfStock')}
          />
          <Select
            state={state}
            onChange={onChange}
            field={'active'}
            options={['active', 'off', 'test']}
          />
          <Select
            state={state}
            onChange={onChange}
            field={'monitor'}
            options={['streetmerchant', 'script']}
          />
          <Select
            state={state}
            onChange={onChange}
            field={'currency'}
            options={['$', '€']}
          />

          <JsonTextarea
            name={'backoffStatusCodes'}
            size={5}
            value={state.backoffStatusCodes}
            onBlur={onChangeJson}
          />
          <JsonTextarea
            name={'labels'}
            size={25}
            value={state.labels}
            onBlur={onChangeJson}
          />
        </div>
      </div>

      <nav className="level is-mobile">
        <div className="level-left"></div>
        <div className="level-right">
          <Buttons>
            <Button color="success" onClick={handleUpdateStore}>
              Save Changes
            </Button>
            <Button color="danger" onClick={cancelEdit}>
              Cancel
            </Button>
          </Buttons>
        </div>
      </nav>
    </div>
  );
}

Store.defaultProps = {
  store: {
    name: '',
    active: 'active',
    monitor: 'streetmerchant',
    timeout: 40,
    pageBackoffMin: 40,
    pageBackoffMax: 40,
    pageSleepMin: 40,
    pageSleepMax: 40,
    ignoreOutOfStock: false,
    dumpHtml: false,
    currency: '€',
    backoffStatusCodes: [],

    labels: {
      inStock: [
        {
          container: '',
          text: [],
        },
      ],
      outOfStock: [
        {
          container: '',
          text: [''],
        },
      ],
      maxPrice: {
        container: '',
        text: [],
        euroFormat: true,
      },
    },
  },
};
