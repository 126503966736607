import React from 'react';
import { useSelector } from 'react-redux';
import R from 'ramda';

import { useDispatch } from 'react-redux';
import Table from '../../molecules/Table/Table';
import Select from '../../atoms/Select/Select';
import AddProduct from '../../molecules/AddProduct';

import {
  attemptDeleteProxy,
  attemptGetProxies,
  attemptUpdateProxy,
} from '../../../store/thunks/proxies';
import AddProxy from '../../molecules/AddProxy/AddProxy';
import Proxy from '../../molecules/Proxy/Proxy';

function ProxyTable({ loading }) {
  const dispatch = useDispatch();

  const deleteProxy = (proxy) => dispatch(attemptDeleteProxy(proxy));
  const updateProxy = (proxy) => dispatch(attemptUpdateProxy(proxy));

  const columns = [
    {
      Header: 'Proxies',
      columns: [
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: '#urls',
          accessor: (row) => JSON.stringify(row.urls.length),
        },
      ],
    },
  ];

  const { proxies } = useSelector(R.pick(['proxies']));

  const renderRowSubComponent = React.useCallback(
    (proxy, onCancel) => (
      <Proxy
        key={proxy._id}
        proxy={proxy}
        onChange={updateProxy}
        onCancel={onCancel}
      />
    ),
    []
  );
  const addRow = React.useCallback(() => <AddProxy />, []);

  return (
    <Table
      columns={columns}
      data={proxies}
      renderRowSubComponent={renderRowSubComponent}
      addRow={addRow}
      loading={loading}
      deleteRow={deleteProxy}
      refresh={true}
      handleRefresh={(_) => dispatch(attemptGetProxies())}
    />
  );
}

export default ProxyTable;
