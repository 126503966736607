import React from 'react'
import {useSelector} from "react-redux";
import R from "ramda";

import {useDispatch} from 'react-redux';
import Table from "../../molecules/Table/Table";
import Select from "../../atoms/Select/Select";
import AddProduct from "../../molecules/AddProduct";
import {attemptDeleteChannel, attemptGetChannels, attemptUpdateChannel} from "../../../store/thunks/channels";
import AddChannel from "../../molecules/AddChannel/AddChannel";
import Channel from "../../molecules/Channel/Channel";
import {attemptGetStores} from "../../../store/thunks/stores";


function ChannelTable({loading}) {
    const dispatch = useDispatch();

    const deleteChannel = (channel) => dispatch(attemptDeleteChannel(channel));
    const updateChannel = (channel) => dispatch(attemptUpdateChannel(channel));



    const columns = [
        {
            Header: "Channels",
            columns: [

                {
                    Header: 'Name',
                    accessor: 'name',
                },
                {
                    Header: 'Test Channel',
                    accessor:  'testChannel',
                },

                {
                    Header: 'countries',
                    accessor: (row) =>  JSON.stringify(row.countries),
                    maxWidth:10
                },
                {
                    Header: 'Categories',
                    accessor: (row) =>  JSON.stringify(row.categories),

                }
            ],
        },
    ]

    const {channels} = useSelector(R.pick(['channels']));

    const renderRowSubComponent = React.useCallback(
        (channel, onCancel) => (

            <Channel key={channel._id} channel={channel} onChange={updateChannel} onCancel={onCancel}/>
        ),
        []
    )
    const addRow = React.useCallback(() => (<AddChannel/>), []);

    return (

        <Table columns={columns} data={channels}
               renderRowSubComponent={renderRowSubComponent} addRow={addRow} loading={loading}
               deleteRow={deleteChannel}   refresh={true}  handleRefresh={(_) => dispatch(attemptGetChannels())}/>

    )
}

export default ChannelTable
