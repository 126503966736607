import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { attemptAddChannel } from '../../../store/thunks/channels';
import { Dialog, Button } from 'evergreen-ui';
import { PlusIcon } from 'evergreen-ui';
import Channel from '../Channel/Channel';

export default function AddChannel(props) {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  const editChannel = () => setEdit(true);

  const handleAddChannel = (channel) => {
    dispatch(attemptAddChannel(channel)).then(() => {
      setEdit(false);
    });
  };
  const onCancel = () => {
    setEdit(false);
  };

  return (
    <div>
      <Button iconBefore={PlusIcon} onClick={editChannel}>
        Add
      </Button>
      <Dialog
        isShown={edit}
        title="Neuen Channel anlegen"
        onCloseComplete={onCancel}
        hasFooter={false}
      >
        <Channel
          inDialog
          onChange={handleAddChannel}
          onCancel={onCancel}
          {...props}
        />
      </Dialog>
    </div>
  );
}
