import request from 'superagent';
import {handleError, handleSuccess} from "../utils/api";

export const postStore = info =>
    request.post('/api/Stores')
        .send(info)
        .then(handleSuccess)
        .catch(handleError);

export const getStores = () =>
    request.get('/api/Stores')
        .then(handleSuccess)
        .catch(handleError);

export const putStore = info =>
    request.put('/api/Stores')
        .send(info)
        .then(handleSuccess)
        .catch(handleError);

export const deleteStore = info =>
    request.delete('/api/Stores/'+info._id + "/")
        .then(handleSuccess)
        .catch(handleError);
