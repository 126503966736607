import React from 'react'
import {Chart as ReactChart} from 'react-charts'

export default function Chart({data}) {
    console.log(data);


    const axes = React.useMemo(
        () => [
            {primary: true, type: 'time', position: 'bottom'},
            {type: 'linear', position: 'left'}
        ],
        []
    )

    return (
        // A react-chart hyper-responsively and continuously fills the available
        // space of its parent element automatically
        <div

        >
            <ReactChart  style={{
                width: '90%',
                height: '400px'
            }} data={data? data: []} axes={axes} tooltip/>

        </div>
    )

}
