import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {faBan} from "@fortawesome/free-solid-svg-icons/faBan";
import React, {useState} from "react";
import Input from "../../atoms/Input/Input";
import Select from "../../atoms/Select/Select";
import JsonTextarea from "../../atoms/JsonTextarea/JsonTextarea";
import {dispatchError} from "../../../utils/api";
import {sortByName} from "../../../utils/sorting";


export function StoreViewBase(props) {
    var initState = Object.assign({...props.store});
    var storeOptions = [ {_id: "-1",name : ""},...props.stores];
    if (initState.store == null ) {

        initState.store =storeOptions[0];
    }

    const [state, setState] = useState(initState);
    const onChange = (e, key) => {
        const {value} = e.target;
        setState(state => ({
                ...state,
                [key]: value

            })
        );
    };

    const onChangeStore = (e, key) => {
        var newState = Object.assign({...state});
        newState[key] = storeOptions.find(store => store._id === e.target.value);
        setState(newState);
    };
    const onChangeJson = (e, key) => {
        var newState = Object.assign({...state});
        if (e === "") {
            newState[key] = {};
        } else {
            try {
                newState[key] = JSON.parse(e);

            } catch (e) {
                if (e instanceof SyntaxError) {
                    dispatchError(  )({status: e.statusCode, body: {message: `In ${key}: ${e.toString()}`}});

                }
            }

        }
        setState(newState);
    };


    const cancelEdit = () => {

        setState(props.store);
        props.onCancel(props.store);
    }
    const storeSelector = (stores, field) => {

        return (

            <div className="field">
                <label className="label">Store</label>
                <div className="control">
                    <div className="select">
                        <select value={state[field]._id} onChange={e => onChangeStore(e, field)}>
                            {storeOptions.sort(sortByName).map(store => <option key={store._id} value={store._id}>{store.name}</option>)}
                        </select>
                    </div>
                </div>
            </div>
        )
    }

    const handleUpdateStore = () => {
        var newState = Object.assign({...state});

        if(newState.store._id ==="-1") {
            newState.store= null;
        }
        props.onChange(newState);

    }


    return (
        <li className="todo box">

            <div className="content">
                <div>

                    {storeSelector(props.stores, "store")}

                    <Select state={state} onChange={onChange} field={"active"}
                            options={["active", "off", "test"]}/>
                    <Input state={state} onChange={onChange} field={"url"}/>
                    <Input state={state} onChange={onChange} field={"affiliateUrl"}/>
                    <JsonTextarea name={"labels"} size={5} value={state.labels}
                                  onBlur={onChangeJson}/>

                </div>


            </div>

            <nav className="level is-mobile">
                <div className="level-left">

                </div>
                <div className="level-right">

                        <span className="icon space-right" onClick={handleUpdateStore}
                              onKeyPress={handleUpdateStore}>
                  <FontAwesomeIcon icon={faSave} size="lg"/>
                </span>

                    <span className="icon" onClick={cancelEdit} onKeyPress={cancelEdit}>
                  <FontAwesomeIcon icon={faBan} size="lg"/>
                </span>
                </div>
            </nav>

        </li>
    )
}

StoreViewBase.defaultProps = {
    store: {
        store: null,
        url: "",
        affiliateUrl: "",
        active: "active",
        comment: "",
    }

}
